/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type ModerateCardContainer_viewer = {
    readonly role: USER_ROLE;
    readonly moderationScopes: {
        readonly scoped: boolean;
        readonly sites: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
        }> | null;
    } | null;
    readonly " $refType": "ModerateCardContainer_viewer";
};
export type ModerateCardContainer_viewer$data = ModerateCardContainer_viewer;
export type ModerateCardContainer_viewer$key = {
    readonly " $data"?: ModerateCardContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModerateCardContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "UserModerationScopes",
            "kind": "LinkedField",
            "name": "moderationScopes",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scoped",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "sites",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = '955a16201fd21b0a78e0519ebb6d4fec';
export default node;
