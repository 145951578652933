/* tslint:disable */
/* eslint-disable */
/* @relayHash dd1c372c7b783b7ab65b43406fdade33 */

import { ConcreteRequest } from "relay-runtime";
export type DeleteWebhookEndpointInput = {
    clientMutationId: string;
    id: string;
};
export type DeleteWebhookEndpointMutationVariables = {
    input: DeleteWebhookEndpointInput;
};
export type DeleteWebhookEndpointMutationResponse = {
    readonly deleteWebhookEndpoint: {
        readonly endpoint: {
            readonly id: string;
        };
    };
};
export type DeleteWebhookEndpointMutation = {
    readonly response: DeleteWebhookEndpointMutationResponse;
    readonly variables: DeleteWebhookEndpointMutationVariables;
};



/*
mutation DeleteWebhookEndpointMutation(
  $input: DeleteWebhookEndpointInput!
) {
  deleteWebhookEndpoint(input: $input) {
    endpoint {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "DeleteWebhookEndpointPayload",
            "kind": "LinkedField",
            "name": "deleteWebhookEndpoint",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "WebhookEndpoint",
                    "kind": "LinkedField",
                    "name": "endpoint",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteWebhookEndpointMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteWebhookEndpointMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "dd1c372c7b783b7ab65b43406fdade33",
            "metadata": {},
            "name": "DeleteWebhookEndpointMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '84f72cdd94ba1b944bd531efc6471d66';
export default node;
