/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type SignInContainer_auth = {
    readonly integrations: {
        readonly local: {
            readonly enabled: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
            };
        };
        readonly facebook: {
            readonly enabled: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
            };
        };
        readonly google: {
            readonly enabled: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
            };
        };
        readonly oidc: {
            readonly enabled: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
            };
        };
    };
    readonly " $fragmentRefs": FragmentRefs<"SignInWithOIDCContainer_auth" | "SignInWithGoogleContainer_auth" | "SignInWithFacebookContainer_auth">;
    readonly " $refType": "SignInContainer_auth";
};
export type SignInContainer_auth$data = SignInContainer_auth;
export type SignInContainer_auth$key = {
    readonly " $data"?: SignInContainer_auth$data;
    readonly " $fragmentRefs": FragmentRefs<"SignInContainer_auth">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
        } as any,
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthenticationTargetFilter",
            "kind": "LinkedField",
            "name": "targetFilter",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "admin",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "SignInContainer_auth",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "AuthIntegrations",
                "kind": "LinkedField",
                "name": "integrations",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "LocalAuthIntegration",
                        "kind": "LinkedField",
                        "name": "local",
                        "plural": false,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "FacebookAuthIntegration",
                        "kind": "LinkedField",
                        "name": "facebook",
                        "plural": false,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "GoogleAuthIntegration",
                        "kind": "LinkedField",
                        "name": "google",
                        "plural": false,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "OIDCAuthIntegration",
                        "kind": "LinkedField",
                        "name": "oidc",
                        "plural": false,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SignInWithOIDCContainer_auth"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SignInWithGoogleContainer_auth"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SignInWithFacebookContainer_auth"
            }
        ],
        "type": "Auth",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'fe4f0d2e1c0daed033fabd61bbd94990';
export default node;
