/* tslint:disable */
/* eslint-disable */
/* @relayHash 67a6566596d208e35afb24f704b5b9d4 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ConversationModalQueryVariables = {
    commentID: string;
};
export type ConversationModalQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"ConversationModalContainer_settings" | "ConversationModalRepliesContainer_settings">;
    };
    readonly coralcomment: {
        readonly " $fragmentRefs": FragmentRefs<"ConversationModalContainer_comment" | "ConversationModalRepliesContainer_comment" | "ConversationModalHeaderContainer_comment">;
    } | null;
};
export type ConversationModalQuery = {
    readonly response: ConversationModalQueryResponse;
    readonly variables: ConversationModalQueryVariables;
};



/*
query ConversationModalQuery(
  $commentID: ID!
) {
  settings {
    ...ConversationModalContainer_settings
    ...ConversationModalRepliesContainer_settings
    id
  }
  coralcomment(id: $commentID) {
    ...ConversationModalContainer_comment
    ...ConversationModalRepliesContainer_comment
    ...ConversationModalHeaderContainer_comment
    id
  }
}

fragment AutomatedActionsContainer_settings on Settings {
  integrations {
    perspective {
      threshold
    }
    external {
      phases {
        name
        id
      }
    }
  }
}

fragment ConversationModalCommentContainer_comment on CoralComment {
  id
  body
  createdAt
  author {
    username
    id
  }
  replyCount
  parent {
    author {
      username
      id
    }
    id
  }
  ...MediaContainer_comment
}

fragment ConversationModalCommentContainer_settings on Settings {
  multisite
  featureFlags
  ...MarkersContainer_settings
}

fragment ConversationModalContainer_comment on CoralComment {
  id
  ...ConversationModalCommentContainer_comment
  rootParent {
    id
  }
  parents(last: 1) {
    edges {
      node {
        id
        ...ConversationModalCommentContainer_comment
        __typename
      }
      cursor
    }
    pageInfo {
      hasPreviousPage
      startCursor
    }
  }
  parentCount
}

fragment ConversationModalContainer_settings on Settings {
  ...ConversationModalCommentContainer_settings
}

fragment ConversationModalHeaderContainer_comment on CoralComment {
  story {
    id
    metadata {
      title
    }
    url
  }
  id
}

fragment ConversationModalRepliesContainer_comment on CoralComment {
  id
  replies(first: 5, orderBy: CREATED_AT_ASC) {
    edges {
      node {
        id
        ...ConversationModalCommentContainer_comment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  replyCount
}

fragment ConversationModalRepliesContainer_settings on Settings {
  ...ConversationModalCommentContainer_settings
}

fragment LinkDetailsContainer_settings on Settings {
  organization {
    url
  }
}

fragment MarkersContainer_settings on Settings {
  ...ModerateCardDetailsContainer_settings
}

fragment MediaContainer_comment on CoralComment {
  id
  site {
    id
  }
  revision {
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
    id
  }
}

fragment ModerateCardDetailsContainer_settings on Settings {
  ...LinkDetailsContainer_settings
  ...AutomatedActionsContainer_settings
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "commentID"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "commentID"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any, v3 = [
        (v2 /*: any*/)
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "concreteType": "CoralUser",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
            },
            (v4 /*: any*/)
        ],
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "replyCount",
        "storageKey": null
    } as any, v9 = {
        "alias": null,
        "args": null,
        "concreteType": "CoralComment",
        "kind": "LinkedField",
        "name": "parent",
        "plural": false,
        "selections": [
            (v7 /*: any*/),
            (v4 /*: any*/)
        ],
        "storageKey": null
    } as any, v10 = [
        (v4 /*: any*/)
    ], v11 = {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": (v10 /*: any*/),
        "storageKey": null
    } as any, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any, v15 = {
        "alias": null,
        "args": null,
        "concreteType": "CommentRevision",
        "kind": "LinkedField",
        "name": "revision",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "media",
                "plural": false,
                "selections": [
                    (v12 /*: any*/),
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            (v2 /*: any*/),
                            (v13 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "width",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "height",
                                "storageKey": null
                            },
                            (v14 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "video",
                                "storageKey": null
                            }
                        ],
                        "type": "GiphyMedia",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": (v3 /*: any*/),
                        "type": "TwitterMedia",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            (v2 /*: any*/),
                            (v14 /*: any*/),
                            (v13 /*: any*/)
                        ],
                        "type": "YouTubeMedia",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": (v3 /*: any*/),
                        "type": "ExternalMedia",
                        "abstractKey": null
                    }
                ],
                "storageKey": null
            },
            (v4 /*: any*/)
        ],
        "storageKey": null
    } as any, v16 = [
        {
            "kind": "Literal",
            "name": "last",
            "value": 1
        } as any
    ], v17 = {
        "alias": null,
        "args": null,
        "concreteType": "CommentEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "CoralComment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                    (v4 /*: any*/),
                    (v5 /*: any*/),
                    (v6 /*: any*/),
                    (v7 /*: any*/),
                    (v8 /*: any*/),
                    (v9 /*: any*/),
                    (v11 /*: any*/),
                    (v15 /*: any*/),
                    (v12 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v18 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 5
        } as any,
        {
            "kind": "Literal",
            "name": "orderBy",
            "value": "CREATED_AT_ASC"
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ConversationModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ConversationModalContainer_settings"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ConversationModalRepliesContainer_settings"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CoralComment",
                    "kind": "LinkedField",
                    "name": "coralcomment",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ConversationModalContainer_comment"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ConversationModalRepliesContainer_comment"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ConversationModalHeaderContainer_comment"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ConversationModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "multisite",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "featureFlags",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": (v3 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalIntegrations",
                            "kind": "LinkedField",
                            "name": "integrations",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PerspectiveExternalIntegration",
                                    "kind": "LinkedField",
                                    "name": "perspective",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "threshold",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CustomExternalIntegration",
                                    "kind": "LinkedField",
                                    "name": "external",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ExternalModerationPhase",
                                            "kind": "LinkedField",
                                            "name": "phases",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "name",
                                                    "storageKey": null
                                                },
                                                (v4 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CoralComment",
                    "kind": "LinkedField",
                    "name": "coralcomment",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v11 /*: any*/),
                        (v15 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoralComment",
                            "kind": "LinkedField",
                            "name": "rootParent",
                            "plural": false,
                            "selections": (v10 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v16 /*: any*/),
                            "concreteType": "CommentsConnection",
                            "kind": "LinkedField",
                            "name": "parents",
                            "plural": false,
                            "selections": [
                                (v17 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasPreviousPage",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "startCursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "parents(last:1)"
                        },
                        {
                            "alias": null,
                            "args": (v16 /*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "ConversationModal_parents",
                            "kind": "LinkedHandle",
                            "name": "parents"
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "parentCount",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v18 /*: any*/),
                            "concreteType": "CommentsConnection",
                            "kind": "LinkedField",
                            "name": "replies",
                            "plural": false,
                            "selections": [
                                (v17 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "replies(first:5,orderBy:\"CREATED_AT_ASC\")"
                        },
                        {
                            "alias": null,
                            "args": (v18 /*: any*/),
                            "filters": [
                                "orderBy"
                            ],
                            "handle": "connection",
                            "key": "ConversationModalReplies_replies",
                            "kind": "LinkedHandle",
                            "name": "replies"
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Story",
                            "kind": "LinkedField",
                            "name": "story",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "StoryMetadata",
                                    "kind": "LinkedField",
                                    "name": "metadata",
                                    "plural": false,
                                    "selections": [
                                        (v13 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "67a6566596d208e35afb24f704b5b9d4",
            "metadata": {},
            "name": "ConversationModalQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'd49ac481742f458bfe44852ce16633c3';
export default node;
