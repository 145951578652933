/* tslint:disable */
/* eslint-disable */
/* @relayHash 8781263fac85ff842274c583a622276e */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type LoginRouteQueryVariables = {};
export type LoginRouteQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"AccountCompletionContainer_viewer" | "LinkAccountContainer_viewer">;
    } | null;
    readonly settings: {
        readonly auth: {
            readonly " $fragmentRefs": FragmentRefs<"AccountCompletionContainer_auth" | "SignInContainer_auth">;
        };
    };
};
export type LoginRouteQuery = {
    readonly response: LoginRouteQueryResponse;
    readonly variables: LoginRouteQueryVariables;
};



/*
query LoginRouteQuery {
  viewer {
    ...AccountCompletionContainer_viewer
    ...LinkAccountContainer_viewer
    id
  }
  settings {
    auth {
      ...AccountCompletionContainer_auth
      ...SignInContainer_auth
    }
    id
  }
}

fragment AccountCompletionContainer_auth on Auth {
  integrations {
    local {
      enabled
      targetFilter {
        admin
        stream
      }
    }
  }
}

fragment AccountCompletionContainer_viewer on CoralUser {
  username
  email
  duplicateEmail
  profiles {
    __typename
  }
}

fragment LinkAccountContainer_viewer on CoralUser {
  duplicateEmail
}

fragment SignInContainer_auth on Auth {
  ...SignInWithOIDCContainer_auth
  ...SignInWithGoogleContainer_auth
  ...SignInWithFacebookContainer_auth
  integrations {
    local {
      enabled
      targetFilter {
        admin
      }
    }
    facebook {
      enabled
      targetFilter {
        admin
      }
    }
    google {
      enabled
      targetFilter {
        admin
      }
    }
    oidc {
      enabled
      targetFilter {
        admin
      }
    }
  }
}

fragment SignInWithFacebookContainer_auth on Auth {
  integrations {
    facebook {
      redirectURL
    }
  }
}

fragment SignInWithGoogleContainer_auth on Auth {
  integrations {
    google {
      redirectURL
    }
  }
}

fragment SignInWithOIDCContainer_auth on Auth {
  integrations {
    oidc {
      name
      redirectURL
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "admin",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "redirectURL",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "concreteType": "AuthenticationTargetFilter",
        "kind": "LinkedField",
        "name": "targetFilter",
        "plural": false,
        "selections": [
            (v2 /*: any*/)
        ],
        "storageKey": null
    } as any, v5 = [
        (v3 /*: any*/),
        (v1 /*: any*/),
        (v4 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "LoginRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoralUser",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "AccountCompletionContainer_viewer"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "LinkAccountContainer_viewer"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Auth",
                            "kind": "LinkedField",
                            "name": "auth",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "AccountCompletionContainer_auth"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "SignInContainer_auth"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "LoginRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoralUser",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duplicateEmail",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "profiles",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Auth",
                            "kind": "LinkedField",
                            "name": "auth",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AuthIntegrations",
                                    "kind": "LinkedField",
                                    "name": "integrations",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "LocalAuthIntegration",
                                            "kind": "LinkedField",
                                            "name": "local",
                                            "plural": false,
                                            "selections": [
                                                (v1 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AuthenticationTargetFilter",
                                                    "kind": "LinkedField",
                                                    "name": "targetFilter",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "stream",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "OIDCAuthIntegration",
                                            "kind": "LinkedField",
                                            "name": "oidc",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "name",
                                                    "storageKey": null
                                                },
                                                (v3 /*: any*/),
                                                (v1 /*: any*/),
                                                (v4 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "GoogleAuthIntegration",
                                            "kind": "LinkedField",
                                            "name": "google",
                                            "plural": false,
                                            "selections": (v5 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "FacebookAuthIntegration",
                                            "kind": "LinkedField",
                                            "name": "facebook",
                                            "plural": false,
                                            "selections": (v5 /*: any*/),
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "8781263fac85ff842274c583a622276e",
            "metadata": {},
            "name": "LoginRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '0ca6d206d9d471ee5f3d8edd8deabf88';
export default node;
