/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type MemberBioContainer_user = {
    readonly bio: string | null;
    readonly " $refType": "MemberBioContainer_user";
};
export type MemberBioContainer_user$data = MemberBioContainer_user;
export type MemberBioContainer_user$key = {
    readonly " $data"?: MemberBioContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"MemberBioContainer_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MemberBioContainer_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bio",
            "storageKey": null
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = '4a0a14b5477703a72c4f6efe8f5db465';
export default node;
