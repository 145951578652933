/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type CommentAuthorContainer_comment = {
    readonly author: {
        readonly id: string;
        readonly username: string | null;
        readonly status: {
            readonly suspension: {
                readonly active: boolean;
            };
            readonly premod: {
                readonly active: boolean;
            };
            readonly ban: {
                readonly active: boolean;
            };
        };
    } | null;
    readonly " $refType": "CommentAuthorContainer_comment";
};
export type CommentAuthorContainer_comment$data = CommentAuthorContainer_comment;
export type CommentAuthorContainer_comment$key = {
    readonly " $data"?: CommentAuthorContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentAuthorContainer_comment">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
        } as any
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "CommentAuthorContainer_comment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "CoralUser",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserStatus",
                        "kind": "LinkedField",
                        "name": "status",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "SuspensionStatus",
                                "kind": "LinkedField",
                                "name": "suspension",
                                "plural": false,
                                "selections": (v0 /*: any*/),
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PremodStatus",
                                "kind": "LinkedField",
                                "name": "premod",
                                "plural": false,
                                "selections": (v0 /*: any*/),
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "BanStatus",
                                "kind": "LinkedField",
                                "name": "ban",
                                "plural": false,
                                "selections": (v0 /*: any*/),
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "CoralComment",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'cdc862007abb0b46c5099fd85f15c4d1';
export default node;
