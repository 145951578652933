/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type ArchiveStoryActionsContainer_viewer = {
    readonly role: USER_ROLE;
    readonly " $refType": "ArchiveStoryActionsContainer_viewer";
};
export type ArchiveStoryActionsContainer_viewer$data = ArchiveStoryActionsContainer_viewer;
export type ArchiveStoryActionsContainer_viewer$key = {
    readonly " $data"?: ArchiveStoryActionsContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ArchiveStoryActionsContainer_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ArchiveStoryActionsContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = '32916e833421b6874015b388fa924333';
export default node;
