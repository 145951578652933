/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type LocaleConfig_formValues = {
    readonly locale: string;
    readonly " $refType": "LocaleConfig_formValues";
};
export type LocaleConfig_formValues$data = LocaleConfig_formValues;
export type LocaleConfig_formValues$key = {
    readonly " $data"?: LocaleConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"LocaleConfig_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LocaleConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locale",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '7063b6816d596111858f3d1d69645050';
export default node;
