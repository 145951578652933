/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type UserHistoryDrawerRejectedComments_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_viewer">;
    readonly " $refType": "UserHistoryDrawerRejectedComments_viewer";
};
export type UserHistoryDrawerRejectedComments_viewer$data = UserHistoryDrawerRejectedComments_viewer;
export type UserHistoryDrawerRejectedComments_viewer$key = {
    readonly " $data"?: UserHistoryDrawerRejectedComments_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"UserHistoryDrawerRejectedComments_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserHistoryDrawerRejectedComments_viewer",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModerateCardContainer_viewer"
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = '433f2324472af22554a00a0a39e7fd28';
export default node;
