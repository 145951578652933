/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ClosedStreamMessageConfig_formValues = {
    readonly closeCommenting: {
        readonly message: string;
    };
    readonly " $refType": "ClosedStreamMessageConfig_formValues";
};
export type ClosedStreamMessageConfig_formValues$data = ClosedStreamMessageConfig_formValues;
export type ClosedStreamMessageConfig_formValues$key = {
    readonly " $data"?: ClosedStreamMessageConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"ClosedStreamMessageConfig_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClosedStreamMessageConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "CloseCommenting",
            "kind": "LinkedField",
            "name": "closeCommenting",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '7ea81234f7b89eda94b8b21b649e0b8d';
export default node;
