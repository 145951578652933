/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type EmailConfigContainer_viewer = {
    readonly email: string | null;
    readonly " $refType": "EmailConfigContainer_viewer";
};
export type EmailConfigContainer_viewer$data = EmailConfigContainer_viewer;
export type EmailConfigContainer_viewer$key = {
    readonly " $data"?: EmailConfigContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"EmailConfigContainer_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailConfigContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = 'b5984d0e41e2dc73661fe8cf7dc332a3';
export default node;
