/* tslint:disable */
/* eslint-disable */
/* @relayHash 6ece6fca79eaa41546c7b6c33bd092aa */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type UserDrawerNotesQueryVariables = {
    userID: string;
};
export type UserDrawerNotesQueryResponse = {
    readonly coraluser: {
        readonly " $fragmentRefs": FragmentRefs<"UserDrawerNotesContainer_user">;
    } | null;
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"UserDrawerNotesContainer_viewer">;
    } | null;
};
export type UserDrawerNotesQuery = {
    readonly response: UserDrawerNotesQueryResponse;
    readonly variables: UserDrawerNotesQueryVariables;
};



/*
query UserDrawerNotesQuery(
  $userID: ID!
) {
  coraluser(id: $userID) {
    ...UserDrawerNotesContainer_user
    id
  }
  viewer {
    ...UserDrawerNotesContainer_viewer
    id
  }
}

fragment UserDrawerNotesContainer_user on CoralUser {
  id
  moderatorNotes {
    id
    body
    createdAt
    createdBy {
      username
      id
    }
  }
}

fragment UserDrawerNotesContainer_viewer on CoralUser {
  id
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userID"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "userID"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserDrawerNotesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CoralUser",
                    "kind": "LinkedField",
                    "name": "coraluser",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserDrawerNotesContainer_user"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoralUser",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserDrawerNotesContainer_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserDrawerNotesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CoralUser",
                    "kind": "LinkedField",
                    "name": "coraluser",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModeratorNote",
                            "kind": "LinkedField",
                            "name": "moderatorNotes",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "body",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdAt",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CoralUser",
                                    "kind": "LinkedField",
                                    "name": "createdBy",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "username",
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoralUser",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "6ece6fca79eaa41546c7b6c33bd092aa",
            "metadata": {},
            "name": "UserDrawerNotesQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '0403203eb0d3d9161807e12c7f930128';
export default node;
