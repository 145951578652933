/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type LinkAccountContainer_viewer = {
    readonly duplicateEmail: string | null;
    readonly " $refType": "LinkAccountContainer_viewer";
};
export type LinkAccountContainer_viewer$data = LinkAccountContainer_viewer;
export type LinkAccountContainer_viewer$key = {
    readonly " $data"?: LinkAccountContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"LinkAccountContainer_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LinkAccountContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duplicateEmail",
            "storageKey": null
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = '07e3e5dafaf34072149cb2cdbb008631';
export default node;
