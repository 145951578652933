/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type ErrorReporterSetUserContainer_viewer = {
    readonly id: string;
    readonly username: string | null;
    readonly role: USER_ROLE;
    readonly " $refType": "ErrorReporterSetUserContainer_viewer";
};
export type ErrorReporterSetUserContainer_viewer$data = ErrorReporterSetUserContainer_viewer;
export type ErrorReporterSetUserContainer_viewer$key = {
    readonly " $data"?: ErrorReporterSetUserContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ErrorReporterSetUserContainer_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ErrorReporterSetUserContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = 'd20b90205130552bb89c69e566cf9939';
export default node;
