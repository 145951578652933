/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type AuthConfigContainer_auth = {
    readonly integrations: {
        readonly facebook: {
            readonly enabled: boolean;
            readonly allowRegistration: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
                readonly stream: boolean;
            };
            readonly clientID: string | null;
            readonly clientSecret: string | null;
        };
        readonly google: {
            readonly enabled: boolean;
            readonly allowRegistration: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
                readonly stream: boolean;
            };
            readonly clientID: string | null;
            readonly clientSecret: string | null;
        };
        readonly sso: {
            readonly enabled: boolean;
            readonly allowRegistration: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
                readonly stream: boolean;
            };
        };
        readonly local: {
            readonly enabled: boolean;
            readonly allowRegistration: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
                readonly stream: boolean;
            };
        };
        readonly oidc: {
            readonly enabled: boolean;
            readonly allowRegistration: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
                readonly stream: boolean;
            };
            readonly name: string | null;
            readonly clientID: string | null;
            readonly clientSecret: string | null;
            readonly authorizationURL: string | null;
            readonly tokenURL: string | null;
            readonly jwksURI: string | null;
            readonly issuer: string | null;
        };
    };
    readonly sessionDuration: number;
    readonly " $fragmentRefs": FragmentRefs<"FacebookConfigContainer_auth" | "GoogleConfigContainer_auth" | "SSOConfigContainer_auth" | "OIDCConfigContainer_auth">;
    readonly " $refType": "AuthConfigContainer_auth";
};
export type AuthConfigContainer_auth$data = AuthConfigContainer_auth;
export type AuthConfigContainer_auth$key = {
    readonly " $data"?: AuthConfigContainer_auth$data;
    readonly " $fragmentRefs": FragmentRefs<"AuthConfigContainer_auth">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "allowRegistration",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "concreteType": "AuthenticationTargetFilter",
        "kind": "LinkedField",
        "name": "targetFilter",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "admin",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stream",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientID",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientSecret",
        "storageKey": null
    } as any, v5 = [
        (v0 /*: any*/),
        (v1 /*: any*/),
        (v2 /*: any*/),
        (v3 /*: any*/),
        (v4 /*: any*/)
    ], v6 = [
        (v0 /*: any*/),
        (v1 /*: any*/),
        (v2 /*: any*/)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "AuthConfigContainer_auth",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "AuthIntegrations",
                "kind": "LinkedField",
                "name": "integrations",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "FacebookAuthIntegration",
                        "kind": "LinkedField",
                        "name": "facebook",
                        "plural": false,
                        "selections": (v5 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "GoogleAuthIntegration",
                        "kind": "LinkedField",
                        "name": "google",
                        "plural": false,
                        "selections": (v5 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SSOAuthIntegration",
                        "kind": "LinkedField",
                        "name": "sso",
                        "plural": false,
                        "selections": (v6 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "LocalAuthIntegration",
                        "kind": "LinkedField",
                        "name": "local",
                        "plural": false,
                        "selections": (v6 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "OIDCAuthIntegration",
                        "kind": "LinkedField",
                        "name": "oidc",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            (v1 /*: any*/),
                            (v2 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                            },
                            (v3 /*: any*/),
                            (v4 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "authorizationURL",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "tokenURL",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "jwksURI",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "issuer",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sessionDuration",
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FacebookConfigContainer_auth"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GoogleConfigContainer_auth"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SSOConfigContainer_auth"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OIDCConfigContainer_auth"
            }
        ],
        "type": "Auth",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'de07a4599438a1e413763071ec06a4dc';
export default node;
