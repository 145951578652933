/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type UserHistoryDrawerRejectedComments_settings = {
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_settings">;
    readonly " $refType": "UserHistoryDrawerRejectedComments_settings";
};
export type UserHistoryDrawerRejectedComments_settings$data = UserHistoryDrawerRejectedComments_settings;
export type UserHistoryDrawerRejectedComments_settings$key = {
    readonly " $data"?: UserHistoryDrawerRejectedComments_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"UserHistoryDrawerRejectedComments_settings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserHistoryDrawerRejectedComments_settings",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModerateCardContainer_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '321b459bb48e5f7511932b8896aa689c';
export default node;
