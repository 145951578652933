/* tslint:disable */
/* eslint-disable */
/* @relayHash cf6e43adc9661d6bde37ab8e30187965 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type RotateExternalModerationPhaseSigningSecretInput = {
    clientMutationId: string;
    id: string;
    inactiveIn: number;
};
export type RotateExternalModerationPhaseSigningSecretMutationVariables = {
    input: RotateExternalModerationPhaseSigningSecretInput;
};
export type RotateExternalModerationPhaseSigningSecretMutationResponse = {
    readonly rotateExternalModerationPhaseSigningSecret: {
        readonly phase: {
            readonly " $fragmentRefs": FragmentRefs<"ConfigureExternalModerationPhaseContainer_phase">;
        };
    };
};
export type RotateExternalModerationPhaseSigningSecretMutation = {
    readonly response: RotateExternalModerationPhaseSigningSecretMutationResponse;
    readonly variables: RotateExternalModerationPhaseSigningSecretMutationVariables;
};



/*
mutation RotateExternalModerationPhaseSigningSecretMutation(
  $input: RotateExternalModerationPhaseSigningSecretInput!
) {
  rotateExternalModerationPhaseSigningSecret(input: $input) {
    phase {
      ...ConfigureExternalModerationPhaseContainer_phase
      id
    }
  }
}

fragment ConfigureExternalModerationPhaseContainer_phase on ExternalModerationPhase {
  ...ExternalModerationPhaseDetails_phase
  ...ExternalModerationPhaseDangerZone_phase
  ...ExternalModerationPhaseStatus_phase
}

fragment ConfigureExternalModerationPhaseForm_phase on ExternalModerationPhase {
  id
  name
  url
  timeout
  format
}

fragment ExternalModerationPhaseDangerZone_phase on ExternalModerationPhase {
  id
  enabled
}

fragment ExternalModerationPhaseDetails_phase on ExternalModerationPhase {
  ...ConfigureExternalModerationPhaseForm_phase
}

fragment ExternalModerationPhaseStatus_phase on ExternalModerationPhase {
  id
  enabled
  signingSecret {
    secret
    createdAt
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RotateExternalModerationPhaseSigningSecretMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "RotateExternalModerationPhaseSigningSecretPayload",
                    "kind": "LinkedField",
                    "name": "rotateExternalModerationPhaseSigningSecret",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalModerationPhase",
                            "kind": "LinkedField",
                            "name": "phase",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ConfigureExternalModerationPhaseContainer_phase"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RotateExternalModerationPhaseSigningSecretMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "RotateExternalModerationPhaseSigningSecretPayload",
                    "kind": "LinkedField",
                    "name": "rotateExternalModerationPhaseSigningSecret",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalModerationPhase",
                            "kind": "LinkedField",
                            "name": "phase",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "timeout",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "format",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "enabled",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SigningSecret",
                                    "kind": "LinkedField",
                                    "name": "signingSecret",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "secret",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "cf6e43adc9661d6bde37ab8e30187965",
            "metadata": {},
            "name": "RotateExternalModerationPhaseSigningSecretMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '7d51b68b813f7e976b24cbb3c816c789';
export default node;
