/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ExternalModerationPhaseRow_phase = {
    readonly id: string;
    readonly name: string;
    readonly enabled: boolean;
    readonly " $refType": "ExternalModerationPhaseRow_phase";
};
export type ExternalModerationPhaseRow_phase$data = ExternalModerationPhaseRow_phase;
export type ExternalModerationPhaseRow_phase$key = {
    readonly " $data"?: ExternalModerationPhaseRow_phase$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalModerationPhaseRow_phase">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalModerationPhaseRow_phase",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
        }
    ],
    "type": "ExternalModerationPhase",
    "abstractKey": null
} as any;
(node as any).hash = 'c4e6238c0c5ee46cc572b08e72872ce8';
export default node;
