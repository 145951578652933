/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type UserRoleChangeContainer_settings = {
    readonly multisite: boolean;
    readonly " $refType": "UserRoleChangeContainer_settings";
};
export type UserRoleChangeContainer_settings$data = UserRoleChangeContainer_settings;
export type UserRoleChangeContainer_settings$key = {
    readonly " $data"?: UserRoleChangeContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"UserRoleChangeContainer_settings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserRoleChangeContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "multisite",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '8351f1b34636cc021ffde874bf631172';
export default node;
