/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
export type STORY_MODE = "COMMENTS" | "QA" | "RATINGS_AND_REVIEWS" | "%future added value";
export type TAG = "ADMIN" | "EXPERT" | "FEATURED" | "MEMBER" | "MODERATOR" | "QUESTION" | "REVIEW" | "STAFF" | "TIP" | "UNANSWERED" | "%future added value";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type USER_STATUS = "ACTIVE" | "BANNED" | "PREMOD" | "SUSPENDED" | "WARNED" | "%future added value";
export type ModerateCardContainer_comment = {
    readonly id: string;
    readonly author: {
        readonly id: string;
        readonly username: string | null;
        readonly status: {
            readonly current: ReadonlyArray<USER_STATUS>;
            readonly ban: {
                readonly active: boolean;
                readonly sites: ReadonlyArray<{
                    readonly id: string;
                    readonly name: string;
                }> | null;
            };
        };
        readonly role: USER_ROLE;
    } | null;
    readonly statusLiveUpdated: boolean | null;
    readonly createdAt: string;
    readonly body: string | null;
    readonly meta: string | null;
    readonly rating: number | null;
    readonly revision: {
        readonly actionCounts: {
            readonly flag: {
                readonly reasons: {
                    readonly COMMENT_DETECTED_BANNED_WORD: number;
                    readonly COMMENT_DETECTED_SUSPECT_WORD: number;
                };
            };
        };
        readonly metadata: {
            readonly wordList: {
                readonly bannedWords: ReadonlyArray<{
                    readonly value: string;
                    readonly index: number;
                    readonly length: number;
                }> | null;
                readonly suspectWords: ReadonlyArray<{
                    readonly value: string;
                    readonly index: number;
                    readonly length: number;
                }> | null;
            } | null;
        };
        readonly id: string;
    } | null;
    readonly tags: ReadonlyArray<{
        readonly code: TAG;
    }>;
    readonly status: COMMENT_STATUS;
    readonly editing: {
        readonly edited: boolean;
    };
    readonly parent: {
        readonly author: {
            readonly id: string;
            readonly username: string | null;
        } | null;
    } | null;
    readonly canModerate: boolean;
    readonly story: {
        readonly id: string;
        readonly metadata: {
            readonly title: string | null;
        } | null;
        readonly settings: {
            readonly mode: STORY_MODE;
        };
        readonly isArchived: boolean;
        readonly isArchiving: boolean;
    };
    readonly site: {
        readonly id: string;
        readonly name: string;
    };
    readonly permalink: string;
    readonly enteredLive: boolean | null;
    readonly deleted: boolean | null;
    readonly " $fragmentRefs": FragmentRefs<"MarkersContainer_comment" | "ModeratedByContainer_comment" | "CommentAuthorContainer_comment" | "MediaContainer_comment">;
    readonly " $refType": "ModerateCardContainer_comment";
};
export type ModerateCardContainer_comment$data = ModerateCardContainer_comment;
export type ModerateCardContainer_comment$key = {
    readonly " $data"?: ModerateCardContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_comment">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any, v2 = [
        (v0 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        } as any
    ], v3 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        } as any,
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "index",
            "storageKey": null
        } as any,
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "length",
            "storageKey": null
        } as any
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ModerateCardContainer_comment",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "CoralUser",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserStatus",
                        "kind": "LinkedField",
                        "name": "status",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "current",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "BanStatus",
                                "kind": "LinkedField",
                                "name": "ban",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "active",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Site",
                                        "kind": "LinkedField",
                                        "name": "sites",
                                        "plural": true,
                                        "selections": (v2 /*: any*/),
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "meta",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rating",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CommentRevision",
                "kind": "LinkedField",
                "name": "revision",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ActionCounts",
                        "kind": "LinkedField",
                        "name": "actionCounts",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "FlagActionCounts",
                                "kind": "LinkedField",
                                "name": "flag",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "FlagReasonActionCounts",
                                        "kind": "LinkedField",
                                        "name": "reasons",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "COMMENT_DETECTED_BANNED_WORD",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "COMMENT_DETECTED_SUSPECT_WORD",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentRevisionMetadata",
                        "kind": "LinkedField",
                        "name": "metadata",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "CommentRevisionWordListMetadata",
                                "kind": "LinkedField",
                                "name": "wordList",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "WordlistMatch",
                                        "kind": "LinkedField",
                                        "name": "bannedWords",
                                        "plural": true,
                                        "selections": (v3 /*: any*/),
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "WordlistMatch",
                                        "kind": "LinkedField",
                                        "name": "suspectWords",
                                        "plural": true,
                                        "selections": (v3 /*: any*/),
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    (v0 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "tags",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "EditInfo",
                "kind": "LinkedField",
                "name": "editing",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "edited",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CoralComment",
                "kind": "LinkedField",
                "name": "parent",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoralUser",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            (v1 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canModerate",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Story",
                "kind": "LinkedField",
                "name": "story",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "StoryMetadata",
                        "kind": "LinkedField",
                        "name": "metadata",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "StorySettings",
                        "kind": "LinkedField",
                        "name": "settings",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mode",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isArchived",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isArchiving",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": (v2 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "permalink",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deleted",
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MarkersContainer_comment"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ModeratedByContainer_comment"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CommentAuthorContainer_comment"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MediaContainer_comment"
            },
            {
                "kind": "ClientExtension",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "statusLiveUpdated",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enteredLive",
                        "storageKey": null
                    }
                ]
            }
        ],
        "type": "CoralComment",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'febbae611c1b1ff3f04ef66ab968001e';
export default node;
