/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ConversationModalContainer_settings = {
    readonly " $fragmentRefs": FragmentRefs<"ConversationModalCommentContainer_settings">;
    readonly " $refType": "ConversationModalContainer_settings";
};
export type ConversationModalContainer_settings$data = ConversationModalContainer_settings;
export type ConversationModalContainer_settings$key = {
    readonly " $data"?: ConversationModalContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"ConversationModalContainer_settings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConversationModalContainer_settings",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConversationModalCommentContainer_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = 'a0f0b5aeca6421e28f12e484c14cf38d';
export default node;
