/* tslint:disable */
/* eslint-disable */
/* @relayHash aa97afeba44f95826244c7c89176aff2 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type DashboardRouteQueryVariables = {};
export type DashboardRouteQueryResponse = {
    readonly firstSite: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string;
            };
        }>;
    };
    readonly viewer: {
        readonly moderationScopes: {
            readonly scoped: boolean;
            readonly sites: ReadonlyArray<{
                readonly id: string;
                readonly name: string;
                readonly " $fragmentRefs": FragmentRefs<"DashboardSiteContainer_site">;
            }> | null;
        } | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"DashboardContainer_query">;
};
export type DashboardRouteQuery = {
    readonly response: DashboardRouteQueryResponse;
    readonly variables: DashboardRouteQueryVariables;
};



/*
query DashboardRouteQuery {
  firstSite: sites(first: 1) {
    edges {
      node {
        id
        name
      }
    }
  }
  viewer {
    moderationScopes {
      scoped
      sites {
        id
        name
        ...DashboardSiteContainer_site
      }
    }
    id
  }
  ...DashboardContainer_query
}

fragment DashboardContainer_query on Query {
  sites(first: 20) {
    edges {
      node {
        id
        name
        ...DashboardSiteContainer_site
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  viewer {
    moderationScopes {
      scoped
      sites {
        id
        name
        ...DashboardSiteContainer_site
      }
    }
    id
  }
}

fragment DashboardSiteContainer_site on Site {
  id
  name
  createdAt
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v2 = {
        "alias": "firstSite",
        "args": [
            {
                "kind": "Literal",
                "name": "first",
                "value": 1
            }
        ],
        "concreteType": "SitesConnection",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "SiteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Site",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            (v1 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": "sites(first:1)"
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "scoped",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any, v5 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 20
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "DashboardRouteQuery",
            "selections": [
                (v2 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoralUser",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "sites",
                                    "plural": true,
                                    "selections": [
                                        (v0 /*: any*/),
                                        (v1 /*: any*/),
                                        {
                                            "args": null,
                                            "kind": "FragmentSpread",
                                            "name": "DashboardSiteContainer_site"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DashboardContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "DashboardRouteQuery",
            "selections": [
                (v2 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoralUser",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "sites",
                                    "plural": true,
                                    "selections": [
                                        (v0 /*: any*/),
                                        (v1 /*: any*/),
                                        (v4 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "SitesConnection",
                    "kind": "LinkedField",
                    "name": "sites",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        (v1 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "sites(first:20)"
                },
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "SitesConfig_sites",
                    "kind": "LinkedHandle",
                    "name": "sites"
                }
            ]
        },
        "params": {
            "id": "aa97afeba44f95826244c7c89176aff2",
            "metadata": {},
            "name": "DashboardRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'b57d914fc07bf4a906a80e41ac18f56c';
export default node;
