/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type TodayTotalsLocal = {
    readonly archivingEnabled: boolean;
    readonly autoArchiveOlderThanMs: number;
    readonly " $refType": "TodayTotalsLocal";
};
export type TodayTotalsLocal$data = TodayTotalsLocal;
export type TodayTotalsLocal$key = {
    readonly " $data"?: TodayTotalsLocal$data;
    readonly " $fragmentRefs": FragmentRefs<"TodayTotalsLocal">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TodayTotalsLocal",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archivingEnabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "autoArchiveOlderThanMs",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any;
(node as any).hash = '50a3c114a57ede678dc0f66bf92a11bc';
export default node;
