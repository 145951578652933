/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type OIDCConfig_formValues = {
    readonly integrations: {
        readonly oidc: {
            readonly enabled: boolean;
            readonly allowRegistration: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
                readonly stream: boolean;
            };
            readonly name: string | null;
            readonly clientID: string | null;
            readonly clientSecret: string | null;
            readonly authorizationURL: string | null;
            readonly tokenURL: string | null;
            readonly jwksURI: string | null;
            readonly issuer: string | null;
        };
    };
    readonly " $refType": "OIDCConfig_formValues";
};
export type OIDCConfig_formValues$data = OIDCConfig_formValues;
export type OIDCConfig_formValues$key = {
    readonly " $data"?: OIDCConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"OIDCConfig_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OIDCConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "OIDCAuthIntegration",
                    "kind": "LinkedField",
                    "name": "oidc",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "allowRegistration",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthenticationTargetFilter",
                            "kind": "LinkedField",
                            "name": "targetFilter",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "admin",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "stream",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "clientID",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "clientSecret",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "authorizationURL",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tokenURL",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "jwksURI",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "issuer",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Auth",
    "abstractKey": null
} as any;
(node as any).hash = 'ceae0b79bf65f04ce02c8c1aa63b235b';
export default node;
