/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type PerspectiveConfig_formValues = {
    readonly integrations: {
        readonly perspective: {
            readonly enabled: boolean;
            readonly endpoint: string | null;
            readonly key: string | null;
            readonly model: string | null;
            readonly threshold: number | null;
            readonly doNotStore: boolean | null;
            readonly sendFeedback: boolean | null;
        };
    };
    readonly " $refType": "PerspectiveConfig_formValues";
};
export type PerspectiveConfig_formValues$data = PerspectiveConfig_formValues;
export type PerspectiveConfig_formValues$key = {
    readonly " $data"?: PerspectiveConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"PerspectiveConfig_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PerspectiveConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "ExternalIntegrations",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "PerspectiveExternalIntegration",
                    "kind": "LinkedField",
                    "name": "perspective",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endpoint",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "key",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "model",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "threshold",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "doNotStore",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sendFeedback",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '9ca742819520737478420dec24bfaa2e';
export default node;
