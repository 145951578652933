import { Localized } from "@fluent/react/compat";
import React, { FunctionComponent, useCallback, useState } from "react";

import {
  ClickOutside,
  FieldSet,
  HorizontalGutter,
  Label,
} from "coral-ui/components/v2";

import { SiteSearchListContainer_query } from "coral-admin/__generated__/SiteSearchListContainer_query.graphql";

import SiteSearchListQuery from "./SiteSearchListQuery";
import SiteSearchTextField from "./SiteSearchTextField";

interface Props {
  onSelect: (id: string | null) => void;
  showOnlyScopedSitesInSearchResults: boolean;
  showSiteSearchLabel: boolean;
  showAllSitesSearchFilterOption: boolean;
  clearTextFieldValueAfterSelect: boolean;
}

const SiteSearch: FunctionComponent<Props> = ({
  onSelect,
  showOnlyScopedSitesInSearchResults,
  showSiteSearchLabel,
  showAllSitesSearchFilterOption
}) => {
 
  const [searchTextFieldValue, setSearchTextFieldValue] = useState<string>(
    "List of sites"
  );
  const [isSiteSearchListVisible, setIsSiteSearchListVisible] = useState<
    boolean
  >(false);
  const [activeSiteID, setActiveSiteID] = useState<string | null>(null);
  const [preventFlicker, setPreventFlicker] = useState(false);

  const onSearch = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      setPreventFlicker(true);
      setIsSiteSearchListVisible(true);
    },
    [setIsSiteSearchListVisible]
  );

  const onSelectSite = useCallback(
    (
      site: SiteSearchListContainer_query["sites"]["edges"][0]["node"] | null
    ) => {
      onSelect(site ? site.id : null);
      setSearchTextFieldValue(site ? site.name : "List of sites");
      setActiveSiteID(site ? site.id : null);
      setIsSiteSearchListVisible(false);
    },
    [
      onSelect,
      setIsSiteSearchListVisible
    ]
  );
  const onClickOutsideHandler = useCallback(() => {
    if (!preventFlicker) {
      setIsSiteSearchListVisible(false);
    }
    setPreventFlicker(false);
  }, [preventFlicker]);

  return (
    <FieldSet>
      <HorizontalGutter spacing={2}>
        {showSiteSearchLabel && (
          <Localized id="stories-filter-sites">
            <Label>Site</Label>
          </Localized>
        )}
        <SiteSearchTextField onSearch={onSearch} value={searchTextFieldValue}/>
        {isSiteSearchListVisible && (
          <ClickOutside onClickOutside={onClickOutsideHandler}>
            <div>
              <SiteSearchListQuery
                onSelect={onSelectSite}
                searchFilter={""}
                activeSiteID={activeSiteID}
                showOnlyScopedSitesInSearchResults={
                  showOnlyScopedSitesInSearchResults
                }
                showAllSitesSearchFilterOption={showAllSitesSearchFilterOption}
              />
            </div>
          </ClickOutside>
        )}
      </HorizontalGutter>
    </FieldSet>
  );
};

export default SiteSearch;
