/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ModerateStoryButton_settings = {
    readonly auth: {
        readonly integrations: {
            readonly sso: {
                readonly enabled: boolean;
                readonly targetFilter: {
                    readonly admin: boolean;
                };
            };
        };
    };
    readonly " $refType": "ModerateStoryButton_settings";
};
export type ModerateStoryButton_settings$data = ModerateStoryButton_settings;
export type ModerateStoryButton_settings$key = {
    readonly " $data"?: ModerateStoryButton_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateStoryButton_settings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModerateStoryButton_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Auth",
            "kind": "LinkedField",
            "name": "auth",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthIntegrations",
                    "kind": "LinkedField",
                    "name": "integrations",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SSOAuthIntegration",
                            "kind": "LinkedField",
                            "name": "sso",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "enabled",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AuthenticationTargetFilter",
                                    "kind": "LinkedField",
                                    "name": "targetFilter",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "admin",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '4249a54a914dfb758ef0edddbfc7c6f3';
export default node;
