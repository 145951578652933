/* tslint:disable */
/* eslint-disable */
/* @relayHash b9274607c66a2d16e6f267d2ae6f1e93 */

import { ConcreteRequest } from "relay-runtime";
export type SpecificSitesSelectedQueryVariables = {
    siteID: string;
};
export type SpecificSitesSelectedQueryResponse = {
    readonly site: {
        readonly name: string;
    } | null;
};
export type SpecificSitesSelectedQuery = {
    readonly response: SpecificSitesSelectedQueryResponse;
    readonly variables: SpecificSitesSelectedQueryVariables;
};



/*
query SpecificSitesSelectedQuery(
  $siteID: ID!
) {
  site(id: $siteID) {
    name
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "siteID"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "siteID"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SpecificSitesSelectedQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SpecificSitesSelectedQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "b9274607c66a2d16e6f267d2ae6f1e93",
            "metadata": {},
            "name": "SpecificSitesSelectedQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'dd22c9a1f609611d1e9448b860d9b979';
export default node;
