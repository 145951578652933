/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type SignInWithFacebookContainer_auth = {
    readonly integrations: {
        readonly facebook: {
            readonly redirectURL: string;
        };
    };
    readonly " $refType": "SignInWithFacebookContainer_auth";
};
export type SignInWithFacebookContainer_auth$data = SignInWithFacebookContainer_auth;
export type SignInWithFacebookContainer_auth$key = {
    readonly " $data"?: SignInWithFacebookContainer_auth$data;
    readonly " $fragmentRefs": FragmentRefs<"SignInWithFacebookContainer_auth">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignInWithFacebookContainer_auth",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "FacebookAuthIntegration",
                    "kind": "LinkedField",
                    "name": "facebook",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "redirectURL",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Auth",
    "abstractKey": null
} as any;
(node as any).hash = 'c900b31de00905cf8fa7f64e3e5da29c';
export default node;
