/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type UserDrawerNotesContainer_viewer = {
    readonly id: string;
    readonly " $refType": "UserDrawerNotesContainer_viewer";
};
export type UserDrawerNotesContainer_viewer$data = UserDrawerNotesContainer_viewer;
export type UserDrawerNotesContainer_viewer$key = {
    readonly " $data"?: UserDrawerNotesContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"UserDrawerNotesContainer_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserDrawerNotesContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = '63a033ef695b8db62c0339a438f0e773';
export default node;
