/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type USER_STATUS = "ACTIVE" | "BANNED" | "PREMOD" | "SUSPENDED" | "WARNED" | "%future added value";
export type UserStatusContainer_user = {
    readonly status: {
        readonly current: ReadonlyArray<USER_STATUS>;
        readonly ban: {
            readonly sites: ReadonlyArray<{
                readonly id: string;
            }> | null;
        };
    };
    readonly " $refType": "UserStatusContainer_user";
};
export type UserStatusContainer_user$data = UserStatusContainer_user;
export type UserStatusContainer_user$key = {
    readonly " $data"?: UserStatusContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"UserStatusContainer_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserStatusContainer_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "UserStatus",
            "kind": "LinkedField",
            "name": "status",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "current",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "BanStatus",
                    "kind": "LinkedField",
                    "name": "ban",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Site",
                            "kind": "LinkedField",
                            "name": "sites",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = '9d535357b048795f21c96f0468b68fbf';
export default node;
