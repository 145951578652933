/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type OrganizationConfigContainer_settings = {
    readonly organization: {
        readonly name: string;
        readonly url: string;
        readonly contactEmail: string;
    };
    readonly " $refType": "OrganizationConfigContainer_settings";
};
export type OrganizationConfigContainer_settings$data = OrganizationConfigContainer_settings;
export type OrganizationConfigContainer_settings$key = {
    readonly " $data"?: OrganizationConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"OrganizationConfigContainer_settings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationConfigContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contactEmail",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '805cec5699a06dcd42369f4f578dfcc2';
export default node;
