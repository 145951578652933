/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type SMTP_formValues = {
    readonly enabled: boolean;
    readonly smtp: {
        readonly host: string | null;
        readonly port: number | null;
        readonly secure: boolean | null;
        readonly authentication: boolean | null;
        readonly username: string | null;
        readonly password: string | null;
    };
    readonly " $refType": "SMTP_formValues";
};
export type SMTP_formValues$data = SMTP_formValues;
export type SMTP_formValues$key = {
    readonly " $data"?: SMTP_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"SMTP_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SMTP_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "SMTP",
            "kind": "LinkedField",
            "name": "smtp",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "host",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "port",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "secure",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "authentication",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "password",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "EmailConfiguration",
    "abstractKey": null
} as any;
(node as any).hash = '59262f8bb5c07d414648bcdb5a368723';
export default node;
