/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type STORY_STATUS = "CLOSED" | "OPEN" | "%future added value";
export type StoryInfoDrawerContainer_story = {
    readonly id: string;
    readonly url: string;
    readonly status: STORY_STATUS;
    readonly scrapedAt: string | null;
    readonly isArchived: boolean;
    readonly isArchiving: boolean;
    readonly metadata: {
        readonly title: string | null;
        readonly author: string | null;
        readonly publishedAt: string | null;
    } | null;
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"StorySettingsContainer_storySettings">;
    };
    readonly " $fragmentRefs": FragmentRefs<"ModerateStoryButton_story" | "ArchiveStoryActionsContainer_story">;
    readonly " $refType": "StoryInfoDrawerContainer_story";
};
export type StoryInfoDrawerContainer_story$data = StoryInfoDrawerContainer_story;
export type StoryInfoDrawerContainer_story$key = {
    readonly " $data"?: StoryInfoDrawerContainer_story$data;
    readonly " $fragmentRefs": FragmentRefs<"StoryInfoDrawerContainer_story">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StoryInfoDrawerContainer_story",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scrapedAt",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchived",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchiving",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "StoryMetadata",
            "kind": "LinkedField",
            "name": "metadata",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "author",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publishedAt",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "StorySettings",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "StorySettingsContainer_storySettings"
                }
            ],
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModerateStoryButton_story"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ArchiveStoryActionsContainer_story"
        }
    ],
    "type": "Story",
    "abstractKey": null
} as any;
(node as any).hash = '0f8497f41b65c805c8f796b33eec8cd9';
export default node;
