/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type CommentStreamLiveUpdates_formValues = {
    readonly live: {
        readonly enabled: boolean;
    };
    readonly " $refType": "CommentStreamLiveUpdates_formValues";
};
export type CommentStreamLiveUpdates_formValues$data = CommentStreamLiveUpdates_formValues;
export type CommentStreamLiveUpdates_formValues$key = {
    readonly " $data"?: CommentStreamLiveUpdates_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentStreamLiveUpdates_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentStreamLiveUpdates_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "LiveConfiguration",
            "kind": "LinkedField",
            "name": "live",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = 'd418e7df06e4bad2cf01d0845f3ca116';
export default node;
