/* tslint:disable */
/* eslint-disable */
/* @relayHash e23a38dea7a68c77b6eb475e893d9d41 */

import { ConcreteRequest } from "relay-runtime";
export type DeleteModeratorNoteInput = {
    clientMutationId: string;
    userID: string;
    id: string;
};
export type DeleteModeratorNoteMutationVariables = {
    input: DeleteModeratorNoteInput;
};
export type DeleteModeratorNoteMutationResponse = {
    readonly deleteModeratorNote: {
        readonly user: {
            readonly id: string;
            readonly moderatorNotes: ReadonlyArray<{
                readonly id: string;
                readonly body: string;
                readonly createdBy: {
                    readonly username: string | null;
                    readonly id: string;
                };
                readonly createdAt: string;
            }>;
        };
        readonly clientMutationId: string;
    };
};
export type DeleteModeratorNoteMutation = {
    readonly response: DeleteModeratorNoteMutationResponse;
    readonly variables: DeleteModeratorNoteMutationVariables;
};



/*
mutation DeleteModeratorNoteMutation(
  $input: DeleteModeratorNoteInput!
) {
  deleteModeratorNote(input: $input) {
    user {
      id
      moderatorNotes {
        id
        body
        createdBy {
          username
          id
        }
        createdAt
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "DeleteModeratorNotePayload",
            "kind": "LinkedField",
            "name": "deleteModeratorNote",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoralUser",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModeratorNote",
                            "kind": "LinkedField",
                            "name": "moderatorNotes",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "body",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CoralUser",
                                    "kind": "LinkedField",
                                    "name": "createdBy",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "username",
                                            "storageKey": null
                                        },
                                        (v1 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdAt",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteModeratorNoteMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteModeratorNoteMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "id": "e23a38dea7a68c77b6eb475e893d9d41",
            "metadata": {},
            "name": "DeleteModeratorNoteMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '35cbd35af4f6cb2c64cd43dbf58757e8';
export default node;
