/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ApprovedQueueRoute_query = {
    readonly coralcomments: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_comment">;
            };
        }>;
    };
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_settings">;
    };
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_viewer">;
    } | null;
    readonly " $refType": "ApprovedQueueRoute_query";
};
export type ApprovedQueueRoute_query$data = ApprovedQueueRoute_query;
export type ApprovedQueueRoute_query$key = {
    readonly " $data"?: ApprovedQueueRoute_query$data;
    readonly " $fragmentRefs": FragmentRefs<"ApprovedQueueRoute_query">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "defaultValue": 5,
            "kind": "LocalArgument",
            "name": "count"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        },
        {
            "defaultValue": "CREATED_AT_DESC",
            "kind": "LocalArgument",
            "name": "orderBy"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "section"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "siteID"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "storyID"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "connection": [
            {
                "count": "count",
                "cursor": "cursor",
                "direction": "forward",
                "path": [
                    "coralcomments"
                ]
            }
        ]
    },
    "name": "ApprovedQueueRoute_query",
    "selections": [
        {
            "alias": "coralcomments",
            "args": null,
            "concreteType": "CommentsConnection",
            "kind": "LinkedField",
            "name": "__ApprovedQueue_coralcomments_connection",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoralComment",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ModerateCardContainer_comment"
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Settings",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ModerateCardContainer_settings"
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "CoralUser",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ModerateCardContainer_viewer"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
} as any;
(node as any).hash = '4579dcddb1097a91b0c0377f76ee7362';
export default node;
