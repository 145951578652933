/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type SiteRoleActions_user = {
    readonly id: string;
    readonly username: string | null;
    readonly role: USER_ROLE;
    readonly membershipScopes: {
        readonly sites: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
        }> | null;
    } | null;
    readonly moderationScopes: {
        readonly scoped: boolean;
        readonly sites: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
        }> | null;
    } | null;
    readonly " $refType": "SiteRoleActions_user";
};
export type SiteRoleActions_user$data = SiteRoleActions_user;
export type SiteRoleActions_user$key = {
    readonly " $data"?: SiteRoleActions_user$data;
    readonly " $fragmentRefs": FragmentRefs<"SiteRoleActions_user">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "sites",
        "plural": true,
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "SiteRoleActions_user",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "UserMembershipScopes",
                "kind": "LinkedField",
                "name": "membershipScopes",
                "plural": false,
                "selections": [
                    (v1 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "UserModerationScopes",
                "kind": "LinkedField",
                "name": "moderationScopes",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scoped",
                        "storageKey": null
                    },
                    (v1 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "type": "CoralUser",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'fe0b8c88cfabac1ae1dc692e14efebd6';
export default node;
