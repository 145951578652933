/* tslint:disable */
/* eslint-disable */
/* @relayHash 31554ed0166bce276f2e82f4b4cfb3f9 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ReactionDetailsContainerPaginationQueryVariables = {
    count: number;
    cursor?: unknown | null;
    commentID: string;
};
export type ReactionDetailsContainerPaginationQueryResponse = {
    readonly coralcomment: {
        readonly " $fragmentRefs": FragmentRefs<"ReactionDetailsContainer_comment">;
    } | null;
};
export type ReactionDetailsContainerPaginationQuery = {
    readonly response: ReactionDetailsContainerPaginationQueryResponse;
    readonly variables: ReactionDetailsContainerPaginationQueryVariables;
};



/*
query ReactionDetailsContainerPaginationQuery(
  $count: Int!
  $cursor: Cursor
  $commentID: ID!
) {
  coralcomment(id: $commentID) {
    ...ReactionDetailsContainer_comment_1G22uz
    id
  }
}

fragment ReactionDetailsContainer_comment_1G22uz on CoralComment {
  id
  reactions(first: $count, after: $cursor) {
    edges {
      node {
        id
        reacter {
          userID
          username
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "commentID"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "count"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "cursor"
    } as any, v3 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "commentID"
        } as any
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v5 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ReactionDetailsContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "CoralComment",
                    "kind": "LinkedField",
                    "name": "coralcomment",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "count",
                                    "variableName": "count"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "cursor",
                                    "variableName": "cursor"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "ReactionDetailsContainer_comment"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ReactionDetailsContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "CoralComment",
                    "kind": "LinkedField",
                    "name": "coralcomment",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": (v5 /*: any*/),
                            "concreteType": "ReactionsConnection",
                            "kind": "LinkedField",
                            "name": "reactions",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ReactionEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Reaction",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Reacter",
                                                    "kind": "LinkedField",
                                                    "name": "reacter",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "userID",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "username",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v5 /*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "ReactionDetails_reactions",
                            "kind": "LinkedHandle",
                            "name": "reactions"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "31554ed0166bce276f2e82f4b4cfb3f9",
            "metadata": {},
            "name": "ReactionDetailsContainerPaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '5e6c04184b595e55a6b17913f67570b7';
export default node;
