/* tslint:disable */
/* eslint-disable */
/* @relayHash 9ff4221089bd8d20a3eee5d7e8e4adb6 */

import { ConcreteRequest } from "relay-runtime";
export type TAG = "ADMIN" | "EXPERT" | "FEATURED" | "MEMBER" | "MODERATOR" | "QUESTION" | "REVIEW" | "STAFF" | "TIP" | "UNANSWERED" | "%future added value";
export type UnfeatureCommentInput = {
    commentID: string;
    clientMutationId: string;
};
export type UnfeatureCommentMutationVariables = {
    input: UnfeatureCommentInput;
};
export type UnfeatureCommentMutationResponse = {
    readonly unfeatureComment: {
        readonly comment: {
            readonly tags: ReadonlyArray<{
                readonly code: TAG;
            }>;
        } | null;
        readonly clientMutationId: string;
    };
};
export type UnfeatureCommentMutation = {
    readonly response: UnfeatureCommentMutationResponse;
    readonly variables: UnfeatureCommentMutationVariables;
};



/*
mutation UnfeatureCommentMutation(
  $input: UnfeatureCommentInput!
) {
  unfeatureComment(input: $input) {
    comment {
      tags {
        code
      }
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "tags",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UnfeatureCommentMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UnfeatureCommentPayload",
                    "kind": "LinkedField",
                    "name": "unfeatureComment",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoralComment",
                            "kind": "LinkedField",
                            "name": "comment",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UnfeatureCommentMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UnfeatureCommentPayload",
                    "kind": "LinkedField",
                    "name": "unfeatureComment",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoralComment",
                            "kind": "LinkedField",
                            "name": "comment",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "9ff4221089bd8d20a3eee5d7e8e4adb6",
            "metadata": {},
            "name": "UnfeatureCommentMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '545104c8f6c9358c05e842a3e2e7ea8e';
export default node;
