/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type SSOSigningSecretRotationContainer_settings = {
    readonly auth: {
        readonly integrations: {
            readonly sso: {
                readonly enabled: boolean;
                readonly signingSecrets: ReadonlyArray<{
                    readonly kid: string;
                    readonly secret: string;
                    readonly createdAt: string;
                    readonly lastUsedAt: string | null;
                    readonly rotatedAt: string | null;
                    readonly inactiveAt: string | null;
                }>;
            };
        };
    };
    readonly " $refType": "SSOSigningSecretRotationContainer_settings";
};
export type SSOSigningSecretRotationContainer_settings$data = SSOSigningSecretRotationContainer_settings;
export type SSOSigningSecretRotationContainer_settings$key = {
    readonly " $data"?: SSOSigningSecretRotationContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"SSOSigningSecretRotationContainer_settings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SSOSigningSecretRotationContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Auth",
            "kind": "LinkedField",
            "name": "auth",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthIntegrations",
                    "kind": "LinkedField",
                    "name": "integrations",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SSOAuthIntegration",
                            "kind": "LinkedField",
                            "name": "sso",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "enabled",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SigningSecret",
                                    "kind": "LinkedField",
                                    "name": "signingSecrets",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "kid",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "secret",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "lastUsedAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "rotatedAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "inactiveAt",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '4adfe4549128432bce6a8d0cdbf2ec68';
export default node;
