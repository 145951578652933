/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type SignInContainerLocal = {
    readonly authError: string | null;
    readonly " $refType": "SignInContainerLocal";
};
export type SignInContainerLocal$data = SignInContainerLocal;
export type SignInContainerLocal$key = {
    readonly " $data"?: SignInContainerLocal$data;
    readonly " $fragmentRefs": FragmentRefs<"SignInContainerLocal">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignInContainerLocal",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "authError",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any;
(node as any).hash = '6a84dbbf1fa5835e4490c797d57e8830';
export default node;
