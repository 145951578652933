/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ClosingCommentStreamsConfig_formValues = {
    readonly closeCommenting: {
        readonly auto: boolean;
        readonly timeout: number;
    };
    readonly " $refType": "ClosingCommentStreamsConfig_formValues";
};
export type ClosingCommentStreamsConfig_formValues$data = ClosingCommentStreamsConfig_formValues;
export type ClosingCommentStreamsConfig_formValues$key = {
    readonly " $data"?: ClosingCommentStreamsConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"ClosingCommentStreamsConfig_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClosingCommentStreamsConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "CloseCommenting",
            "kind": "LinkedField",
            "name": "closeCommenting",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "auto",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timeout",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '426914a619d0209b4e6a2563452b1968';
export default node;
