/* tslint:disable */
/* eslint-disable */
/* @relayHash 7d4d3f86d62a0a4620c5e05d8cae0bb3 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type SiteSearchListContainerPaginationQueryVariables = {
    count: number;
    cursor?: unknown | null;
    searchFilter: string;
};
export type SiteSearchListContainerPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"SiteSearchListContainer_query">;
};
export type SiteSearchListContainerPaginationQuery = {
    readonly response: SiteSearchListContainerPaginationQueryResponse;
    readonly variables: SiteSearchListContainerPaginationQueryVariables;
};



/*
query SiteSearchListContainerPaginationQuery(
  $count: Int!
  $cursor: Cursor
  $searchFilter: String!
) {
  ...SiteSearchListContainer_query_aN7PH
}

fragment SiteSearchListContainer_query_aN7PH on Query {
  viewer {
    moderationScopes {
      sites {
        id
      }
    }
    id
  }
  sites(first: $count, after: $cursor, query: $searchFilter) {
    edges {
      node {
        id
        name
        canModerate
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "count"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "searchFilter"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any,
        {
            "kind": "Variable",
            "name": "query",
            "variableName": "searchFilter"
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SiteSearchListContainerPaginationQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        },
                        {
                            "kind": "Variable",
                            "name": "searchFilter",
                            "variableName": "searchFilter"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "SiteSearchListContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SiteSearchListContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoralUser",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "sites",
                                    "plural": true,
                                    "selections": [
                                        (v1 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "SitesConnection",
                    "kind": "LinkedField",
                    "name": "sites",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "canModerate",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "filters": [
                        "query"
                    ],
                    "handle": "connection",
                    "key": "SitesConfig_sites",
                    "kind": "LinkedHandle",
                    "name": "sites"
                }
            ]
        },
        "params": {
            "id": "7d4d3f86d62a0a4620c5e05d8cae0bb3",
            "metadata": {},
            "name": "SiteSearchListContainerPaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '4537e8cdcaabfc72c17a7ef13984fe1a';
export default node;
