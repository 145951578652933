/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type MODERATION_MODE = "POST" | "PRE" | "SPECIFIC_SITES_PRE" | "%future added value";
export type STORY_MODE = "COMMENTS" | "QA" | "RATINGS_AND_REVIEWS" | "%future added value";
export type StorySettingsContainer_storySettings = {
    readonly mode: STORY_MODE;
    readonly moderation: MODERATION_MODE;
    readonly premodLinksEnable: boolean;
    readonly experts: ReadonlyArray<{
        readonly id: string;
        readonly username: string | null;
    }>;
    readonly " $refType": "StorySettingsContainer_storySettings";
};
export type StorySettingsContainer_storySettings$data = StorySettingsContainer_storySettings;
export type StorySettingsContainer_storySettings$key = {
    readonly " $data"?: StorySettingsContainer_storySettings$data;
    readonly " $fragmentRefs": FragmentRefs<"StorySettingsContainer_storySettings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StorySettingsContainer_storySettings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mode",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "moderation",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "premodLinksEnable",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "CoralUser",
            "kind": "LinkedField",
            "name": "experts",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "StorySettings",
    "abstractKey": null
} as any;
(node as any).hash = '96ff026b04e586ba40ef61a52e1e3632';
export default node;
