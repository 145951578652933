/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type UserDrawerNotesContainer_user = {
    readonly id: string;
    readonly moderatorNotes: ReadonlyArray<{
        readonly id: string;
        readonly body: string;
        readonly createdAt: string;
        readonly createdBy: {
            readonly username: string | null;
            readonly id: string;
        };
    }>;
    readonly " $refType": "UserDrawerNotesContainer_user";
};
export type UserDrawerNotesContainer_user$data = UserDrawerNotesContainer_user;
export type UserDrawerNotesContainer_user$key = {
    readonly " $data"?: UserDrawerNotesContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"UserDrawerNotesContainer_user">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "UserDrawerNotesContainer_user",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "ModeratorNote",
                "kind": "LinkedField",
                "name": "moderatorNotes",
                "plural": true,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoralUser",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                            },
                            (v0 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "CoralUser",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'efb11b6f41c24f8d2db15262877ceba2';
export default node;
