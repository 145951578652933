/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ExpertSelectionContainer_query = {
    readonly viewer: {
        readonly id: string;
        readonly username: string | null;
    } | null;
    readonly story: {
        readonly settings: {
            readonly experts: ReadonlyArray<{
                readonly id: string;
                readonly email: string | null;
                readonly username: string | null;
            }>;
        };
    } | null;
    readonly coralusers: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly email: string | null;
                readonly username: string | null;
            };
        }>;
    };
    readonly " $refType": "ExpertSelectionContainer_query";
};
export type ExpertSelectionContainer_query$data = ExpertSelectionContainer_query;
export type ExpertSelectionContainer_query$key = {
    readonly " $data"?: ExpertSelectionContainer_query$data;
    readonly " $fragmentRefs": FragmentRefs<"ExpertSelectionContainer_query">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [
            {
                "defaultValue": 10,
                "kind": "LocalArgument",
                "name": "count"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cursor"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "roleFilter"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "searchFilter"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "statusFilter"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "storyID"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "count",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": [
                        "coralusers"
                    ]
                }
            ]
        },
        "name": "ExpertSelectionContainer_query",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "CoralUser",
                "kind": "LinkedField",
                "name": "viewer",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "id",
                        "variableName": "storyID"
                    }
                ],
                "concreteType": "Story",
                "kind": "LinkedField",
                "name": "story",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "StorySettings",
                        "kind": "LinkedField",
                        "name": "settings",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "CoralUser",
                                "kind": "LinkedField",
                                "name": "experts",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    (v2 /*: any*/),
                                    (v1 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": "coralusers",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "query",
                        "variableName": "searchFilter"
                    },
                    {
                        "kind": "Variable",
                        "name": "role",
                        "variableName": "roleFilter"
                    },
                    {
                        "kind": "Variable",
                        "name": "status",
                        "variableName": "statusFilter"
                    }
                ],
                "concreteType": "UsersConnection",
                "kind": "LinkedField",
                "name": "__ExpertSelection_coralusers_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "CoralUser",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    (v2 /*: any*/),
                                    (v1 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'd8d169c26075b0c0700c848023c78daf';
export default node;
