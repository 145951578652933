/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type EndpointDangerZone_webhookEndpoint = {
    readonly id: string;
    readonly enabled: boolean;
    readonly " $refType": "EndpointDangerZone_webhookEndpoint";
};
export type EndpointDangerZone_webhookEndpoint$data = EndpointDangerZone_webhookEndpoint;
export type EndpointDangerZone_webhookEndpoint$key = {
    readonly " $data"?: EndpointDangerZone_webhookEndpoint$data;
    readonly " $fragmentRefs": FragmentRefs<"EndpointDangerZone_webhookEndpoint">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EndpointDangerZone_webhookEndpoint",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
        }
    ],
    "type": "WebhookEndpoint",
    "abstractKey": null
} as any;
(node as any).hash = 'b6238e4f3cd3fba9545fdff1ea2355ee';
export default node;
