/* tslint:disable */
/* eslint-disable */
/* @relayHash 1b87176e89a9a38ad7ce6e5fe3da6842 */

import { ConcreteRequest } from "relay-runtime";
export type RotateSSOSigningSecretInput = {
    clientMutationId: string;
    inactiveIn: number;
};
export type RotateSSOSigningSecretMutationVariables = {
    input: RotateSSOSigningSecretInput;
};
export type RotateSSOSigningSecretMutationResponse = {
    readonly rotateSSOSigningSecret: {
        readonly settings: {
            readonly auth: {
                readonly integrations: {
                    readonly sso: {
                        readonly enabled: boolean;
                        readonly signingSecrets: ReadonlyArray<{
                            readonly kid: string;
                            readonly secret: string;
                            readonly createdAt: string;
                            readonly lastUsedAt: string | null;
                            readonly rotatedAt: string | null;
                            readonly inactiveAt: string | null;
                        }>;
                    };
                };
            };
        } | null;
        readonly clientMutationId: string;
    };
};
export type RotateSSOSigningSecretMutation = {
    readonly response: RotateSSOSigningSecretMutationResponse;
    readonly variables: RotateSSOSigningSecretMutationVariables;
};



/*
mutation RotateSSOSigningSecretMutation(
  $input: RotateSSOSigningSecretInput!
) {
  rotateSSOSigningSecret(input: $input) {
    settings {
      auth {
        integrations {
          sso {
            enabled
            signingSecrets {
              kid
              secret
              createdAt
              lastUsedAt
              rotatedAt
              inactiveAt
            }
          }
        }
      }
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "Auth",
        "kind": "LinkedField",
        "name": "auth",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "AuthIntegrations",
                "kind": "LinkedField",
                "name": "integrations",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SSOAuthIntegration",
                        "kind": "LinkedField",
                        "name": "sso",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "enabled",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "SigningSecret",
                                "kind": "LinkedField",
                                "name": "signingSecrets",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "kid",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "secret",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "createdAt",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "lastUsedAt",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "rotatedAt",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "inactiveAt",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RotateSSOSigningSecretMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "RotateSSOSigningSecretPayload",
                    "kind": "LinkedField",
                    "name": "rotateSSOSigningSecret",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RotateSSOSigningSecretMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "RotateSSOSigningSecretPayload",
                    "kind": "LinkedField",
                    "name": "rotateSSOSigningSecret",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "1b87176e89a9a38ad7ce6e5fe3da6842",
            "metadata": {},
            "name": "RotateSSOSigningSecretMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'a64bd869fd03ebceedd67b4ba36e9f5f';
export default node;
