/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ExternalModerationPhaseDetails_phase = {
    readonly " $fragmentRefs": FragmentRefs<"ConfigureExternalModerationPhaseForm_phase">;
    readonly " $refType": "ExternalModerationPhaseDetails_phase";
};
export type ExternalModerationPhaseDetails_phase$data = ExternalModerationPhaseDetails_phase;
export type ExternalModerationPhaseDetails_phase$key = {
    readonly " $data"?: ExternalModerationPhaseDetails_phase$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalModerationPhaseDetails_phase">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalModerationPhaseDetails_phase",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConfigureExternalModerationPhaseForm_phase"
        }
    ],
    "type": "ExternalModerationPhase",
    "abstractKey": null
} as any;
(node as any).hash = 'bb3a56b23d5176efe03832a0d51f80f1';
export default node;
