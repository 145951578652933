/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type UserHistoryDrawerAllCommentsLocal = {
    readonly archivingEnabled: boolean;
    readonly autoArchiveOlderThanMs: number;
    readonly " $refType": "UserHistoryDrawerAllCommentsLocal";
};
export type UserHistoryDrawerAllCommentsLocal$data = UserHistoryDrawerAllCommentsLocal;
export type UserHistoryDrawerAllCommentsLocal$key = {
    readonly " $data"?: UserHistoryDrawerAllCommentsLocal$data;
    readonly " $fragmentRefs": FragmentRefs<"UserHistoryDrawerAllCommentsLocal">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserHistoryDrawerAllCommentsLocal",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archivingEnabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "autoArchiveOlderThanMs",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any;
(node as any).hash = '92de4cde6500cdff8a87e89d8dfa6e44';
export default node;
