/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type SSOConfigContainer_auth = {
    readonly " $fragmentRefs": FragmentRefs<"SSOConfig_formValues">;
    readonly " $refType": "SSOConfigContainer_auth";
};
export type SSOConfigContainer_auth$data = SSOConfigContainer_auth;
export type SSOConfigContainer_auth$key = {
    readonly " $data"?: SSOConfigContainer_auth$data;
    readonly " $fragmentRefs": FragmentRefs<"SSOConfigContainer_auth">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SSOConfigContainer_auth",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SSOConfig_formValues"
        }
    ],
    "type": "Auth",
    "abstractKey": null
} as any;
(node as any).hash = 'd54ed96a5a8e55b4aaaf1b5f9b1f50ca';
export default node;
