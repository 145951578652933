/* tslint:disable */
/* eslint-disable */
/* @relayHash af61797cd5999786f356af299e2c2ee8 */

import { ConcreteRequest } from "relay-runtime";
export type USER_STATUS = "ACTIVE" | "BANNED" | "PREMOD" | "SUSPENDED" | "WARNED" | "%future added value";
export type WarnUserInput = {
    userID: string;
    clientMutationId: string;
    message: string;
};
export type WarnUserMutationVariables = {
    input: WarnUserInput;
};
export type WarnUserMutationResponse = {
    readonly warnUser: {
        readonly user: {
            readonly id: string;
            readonly status: {
                readonly current: ReadonlyArray<USER_STATUS>;
                readonly warning: {
                    readonly active: boolean;
                    readonly history: ReadonlyArray<{
                        readonly active: boolean;
                        readonly createdAt: string;
                        readonly createdBy: {
                            readonly id: string;
                            readonly username: string | null;
                        };
                    }>;
                };
            };
        };
        readonly clientMutationId: string;
    };
};
export type WarnUserMutation = {
    readonly response: WarnUserMutationResponse;
    readonly variables: WarnUserMutationVariables;
};



/*
mutation WarnUserMutation(
  $input: WarnUserInput!
) {
  warnUser(input: $input) {
    user {
      id
      status {
        current
        warning {
          active
          history {
            active
            createdAt
            createdBy {
              id
              username
            }
          }
        }
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
    } as any, v3 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "WarnUserPayload",
            "kind": "LinkedField",
            "name": "warnUser",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoralUser",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserStatus",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "current",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "WarningStatus",
                                    "kind": "LinkedField",
                                    "name": "warning",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "WarningStatusHistory",
                                            "kind": "LinkedField",
                                            "name": "history",
                                            "plural": true,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "createdAt",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CoralUser",
                                                    "kind": "LinkedField",
                                                    "name": "createdBy",
                                                    "plural": false,
                                                    "selections": [
                                                        (v1 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "username",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "WarnUserMutation",
            "selections": (v3 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "WarnUserMutation",
            "selections": (v3 /*: any*/)
        },
        "params": {
            "id": "af61797cd5999786f356af299e2c2ee8",
            "metadata": {},
            "name": "WarnUserMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'd49f3a9edba25d1202ff4b15cd2c2b44';
export default node;
