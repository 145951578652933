/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type FlattenRepliesConfig_formValues = {
    readonly flattenReplies: boolean;
    readonly " $refType": "FlattenRepliesConfig_formValues";
};
export type FlattenRepliesConfig_formValues$data = FlattenRepliesConfig_formValues;
export type FlattenRepliesConfig_formValues$key = {
    readonly " $data"?: FlattenRepliesConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"FlattenRepliesConfig_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FlattenRepliesConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "flattenReplies",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = 'f4341ca77f1f5daea135499361c8d150';
export default node;
