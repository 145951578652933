/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type SitewideCommentingConfig_formValues = {
    readonly disableCommenting: {
        readonly enabled: boolean;
        readonly message: string;
    };
    readonly " $refType": "SitewideCommentingConfig_formValues";
};
export type SitewideCommentingConfig_formValues$data = SitewideCommentingConfig_formValues;
export type SitewideCommentingConfig_formValues$key = {
    readonly " $data"?: SitewideCommentingConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"SitewideCommentingConfig_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SitewideCommentingConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "DisableCommenting",
            "kind": "LinkedField",
            "name": "disableCommenting",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = 'e5aa3bb7a55bf66d6e79abe79ecd619a';
export default node;
