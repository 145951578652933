/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type UserRoleChangeContainer_viewer = {
    readonly id: string;
    readonly role: USER_ROLE;
    readonly moderationScopes: {
        readonly scoped: boolean;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"SiteRoleActions_viewer">;
    readonly " $refType": "UserRoleChangeContainer_viewer";
};
export type UserRoleChangeContainer_viewer$data = UserRoleChangeContainer_viewer;
export type UserRoleChangeContainer_viewer$key = {
    readonly " $data"?: UserRoleChangeContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"UserRoleChangeContainer_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserRoleChangeContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "UserModerationScopes",
            "kind": "LinkedField",
            "name": "moderationScopes",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scoped",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SiteRoleActions_viewer"
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = '59a4f2f7eb0e1fa070aa98e9cd6cc000';
export default node;
