/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type EndpointDetails_settings = {
    readonly " $fragmentRefs": FragmentRefs<"ConfigureWebhookEndpointForm_settings">;
    readonly " $refType": "EndpointDetails_settings";
};
export type EndpointDetails_settings$data = EndpointDetails_settings;
export type EndpointDetails_settings$key = {
    readonly " $data"?: EndpointDetails_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"EndpointDetails_settings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EndpointDetails_settings",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConfigureWebhookEndpointForm_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '770719af83ddf9bd8a971082ce3ead19';
export default node;
