/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ArchiveStoryActionsContainer_local = {
    readonly archivingEnabled: boolean;
    readonly " $refType": "ArchiveStoryActionsContainer_local";
};
export type ArchiveStoryActionsContainer_local$data = ArchiveStoryActionsContainer_local;
export type ArchiveStoryActionsContainer_local$key = {
    readonly " $data"?: ArchiveStoryActionsContainer_local$data;
    readonly " $fragmentRefs": FragmentRefs<"ArchiveStoryActionsContainer_local">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ArchiveStoryActionsContainer_local",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archivingEnabled",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any;
(node as any).hash = '7195650a769909bde5b0226ce34f1923';
export default node;
