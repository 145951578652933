/* tslint:disable */
/* eslint-disable */
/* @relayHash 5fdc3ddda5b83285ef954afd1d7bb49a */

import { ConcreteRequest } from "relay-runtime";
export type DeleteExternalModerationPhaseInput = {
    clientMutationId: string;
    id: string;
};
export type DeleteExternalModerationPhaseMutationVariables = {
    input: DeleteExternalModerationPhaseInput;
};
export type DeleteExternalModerationPhaseMutationResponse = {
    readonly deleteExternalModerationPhase: {
        readonly phase: {
            readonly id: string;
        };
    };
};
export type DeleteExternalModerationPhaseMutation = {
    readonly response: DeleteExternalModerationPhaseMutationResponse;
    readonly variables: DeleteExternalModerationPhaseMutationVariables;
};



/*
mutation DeleteExternalModerationPhaseMutation(
  $input: DeleteExternalModerationPhaseInput!
) {
  deleteExternalModerationPhase(input: $input) {
    phase {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "DeleteExternalModerationPhasePayload",
            "kind": "LinkedField",
            "name": "deleteExternalModerationPhase",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalModerationPhase",
                    "kind": "LinkedField",
                    "name": "phase",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteExternalModerationPhaseMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteExternalModerationPhaseMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "5fdc3ddda5b83285ef954afd1d7bb49a",
            "metadata": {},
            "name": "DeleteExternalModerationPhaseMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'af34d05e6783a1be17948a216f24a214';
export default node;
