/* tslint:disable */
/* eslint-disable */
/* @relayHash 3fb8978320c4ce2b60b38a4614e8b333 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type DisableExternalModerationPhaseInput = {
    clientMutationId: string;
    id: string;
};
export type DisableExternalModerationPhaseMutationVariables = {
    input: DisableExternalModerationPhaseInput;
};
export type DisableExternalModerationPhaseMutationResponse = {
    readonly disableExternalModerationPhase: {
        readonly phase: {
            readonly " $fragmentRefs": FragmentRefs<"ConfigureExternalModerationPhaseContainer_phase">;
        };
    };
};
export type DisableExternalModerationPhaseMutation = {
    readonly response: DisableExternalModerationPhaseMutationResponse;
    readonly variables: DisableExternalModerationPhaseMutationVariables;
};



/*
mutation DisableExternalModerationPhaseMutation(
  $input: DisableExternalModerationPhaseInput!
) {
  disableExternalModerationPhase(input: $input) {
    phase {
      ...ConfigureExternalModerationPhaseContainer_phase
      id
    }
  }
}

fragment ConfigureExternalModerationPhaseContainer_phase on ExternalModerationPhase {
  ...ExternalModerationPhaseDetails_phase
  ...ExternalModerationPhaseDangerZone_phase
  ...ExternalModerationPhaseStatus_phase
}

fragment ConfigureExternalModerationPhaseForm_phase on ExternalModerationPhase {
  id
  name
  url
  timeout
  format
}

fragment ExternalModerationPhaseDangerZone_phase on ExternalModerationPhase {
  id
  enabled
}

fragment ExternalModerationPhaseDetails_phase on ExternalModerationPhase {
  ...ConfigureExternalModerationPhaseForm_phase
}

fragment ExternalModerationPhaseStatus_phase on ExternalModerationPhase {
  id
  enabled
  signingSecret {
    secret
    createdAt
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DisableExternalModerationPhaseMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DisableExternalModerationPhasePayload",
                    "kind": "LinkedField",
                    "name": "disableExternalModerationPhase",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalModerationPhase",
                            "kind": "LinkedField",
                            "name": "phase",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ConfigureExternalModerationPhaseContainer_phase"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DisableExternalModerationPhaseMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DisableExternalModerationPhasePayload",
                    "kind": "LinkedField",
                    "name": "disableExternalModerationPhase",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalModerationPhase",
                            "kind": "LinkedField",
                            "name": "phase",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "timeout",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "format",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "enabled",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SigningSecret",
                                    "kind": "LinkedField",
                                    "name": "signingSecret",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "secret",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "3fb8978320c4ce2b60b38a4614e8b333",
            "metadata": {},
            "name": "DisableExternalModerationPhaseMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '5a465549d4b05b7ea07a8d8a89b64703';
export default node;
