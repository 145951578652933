/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type NewCommentersConfigContainer_settings = {
    readonly multisite: boolean;
    readonly " $refType": "NewCommentersConfigContainer_settings";
};
export type NewCommentersConfigContainer_settings$data = NewCommentersConfigContainer_settings;
export type NewCommentersConfigContainer_settings$key = {
    readonly " $data"?: NewCommentersConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"NewCommentersConfigContainer_settings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewCommentersConfigContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "multisite",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '4db561eeb16bb6d7a7dbf389d24295fe';
export default node;
