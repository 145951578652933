/* tslint:disable */
/* eslint-disable */
/* @relayHash 75460eb654d654be05271ba7bdd50a63 */

import { ConcreteRequest } from "relay-runtime";
export type ReviewCommentFlagInput = {
    clientMutationId: string;
    id: string;
};
export type MarkFlagReviewedMutationVariables = {
    input: ReviewCommentFlagInput;
};
export type MarkFlagReviewedMutationResponse = {
    readonly reviewCommentFlag: {
        readonly flag: {
            readonly id: string;
            readonly reviewed: boolean;
        };
        readonly clientMutationId: string;
    };
};
export type MarkFlagReviewedMutation = {
    readonly response: MarkFlagReviewedMutationResponse;
    readonly variables: MarkFlagReviewedMutationVariables;
};



/*
mutation MarkFlagReviewedMutation(
  $input: ReviewCommentFlagInput!
) {
  reviewCommentFlag(input: $input) {
    flag {
      id
      reviewed
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "ReviewCommentFlagPayload",
            "kind": "LinkedField",
            "name": "reviewCommentFlag",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Flag",
                    "kind": "LinkedField",
                    "name": "flag",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reviewed",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "MarkFlagReviewedMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "MarkFlagReviewedMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "75460eb654d654be05271ba7bdd50a63",
            "metadata": {},
            "name": "MarkFlagReviewedMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '58d6059100c652f74b22b8dd6a988120';
export default node;
