/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type UserMenuContainer_viewer = {
    readonly username: string | null;
    readonly " $refType": "UserMenuContainer_viewer";
};
export type UserMenuContainer_viewer$data = UserMenuContainer_viewer;
export type UserMenuContainer_viewer$key = {
    readonly " $data"?: UserMenuContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"UserMenuContainer_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserMenuContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = 'c1dc687e18ca714900f66529cd1ab302';
export default node;
