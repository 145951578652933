/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type UserHistoryDrawerContainer_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"UserStatusChangeContainer_viewer">;
    readonly " $refType": "UserHistoryDrawerContainer_viewer";
};
export type UserHistoryDrawerContainer_viewer$data = UserHistoryDrawerContainer_viewer;
export type UserHistoryDrawerContainer_viewer$key = {
    readonly " $data"?: UserHistoryDrawerContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"UserHistoryDrawerContainer_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserHistoryDrawerContainer_viewer",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserStatusChangeContainer_viewer"
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = '43e551abaed16996d39af6d27f354208';
export default node;
