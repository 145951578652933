/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type RecentHistoryContainer_user = {
    readonly recentCommentHistory: {
        readonly statuses: {
            readonly NONE: number;
            readonly APPROVED: number;
            readonly REJECTED: number;
            readonly PREMOD: number;
            readonly SYSTEM_WITHHELD: number;
        };
    };
    readonly " $refType": "RecentHistoryContainer_user";
};
export type RecentHistoryContainer_user$data = RecentHistoryContainer_user;
export type RecentHistoryContainer_user$key = {
    readonly " $data"?: RecentHistoryContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"RecentHistoryContainer_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecentHistoryContainer_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "RecentCommentHistory",
            "kind": "LinkedField",
            "name": "recentCommentHistory",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentStatusCounts",
                    "kind": "LinkedField",
                    "name": "statuses",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "NONE",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "APPROVED",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "REJECTED",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "PREMOD",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "SYSTEM_WITHHELD",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = '3b34e4b7d5adc323e4c23b0a2affebac';
export default node;
