/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type EndpointDetails_webhookEndpoint = {
    readonly " $fragmentRefs": FragmentRefs<"ConfigureWebhookEndpointForm_webhookEndpoint">;
    readonly " $refType": "EndpointDetails_webhookEndpoint";
};
export type EndpointDetails_webhookEndpoint$data = EndpointDetails_webhookEndpoint;
export type EndpointDetails_webhookEndpoint$key = {
    readonly " $data"?: EndpointDetails_webhookEndpoint$data;
    readonly " $fragmentRefs": FragmentRefs<"EndpointDetails_webhookEndpoint">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EndpointDetails_webhookEndpoint",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConfigureWebhookEndpointForm_webhookEndpoint"
        }
    ],
    "type": "WebhookEndpoint",
    "abstractKey": null
} as any;
(node as any).hash = '3a07aba859a2bf80e1c6db33deddf15f';
export default node;
