/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type OrganizationContactEmailConfig_formValues = {
    readonly organization: {
        readonly contactEmail: string;
    };
    readonly " $refType": "OrganizationContactEmailConfig_formValues";
};
export type OrganizationContactEmailConfig_formValues$data = OrganizationContactEmailConfig_formValues;
export type OrganizationContactEmailConfig_formValues$key = {
    readonly " $data"?: OrganizationContactEmailConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"OrganizationContactEmailConfig_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationContactEmailConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contactEmail",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '756672cad5b730ca9f26e3280908e440';
export default node;
