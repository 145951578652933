/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ReactionDetailsContainer_comment = {
    readonly id: string;
    readonly reactions: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string | null;
                readonly reacter: {
                    readonly userID: string | null;
                    readonly username: string | null;
                } | null;
            };
        }>;
    };
    readonly " $refType": "ReactionDetailsContainer_comment";
};
export type ReactionDetailsContainer_comment$data = ReactionDetailsContainer_comment;
export type ReactionDetailsContainer_comment$key = {
    readonly " $data"?: ReactionDetailsContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReactionDetailsContainer_comment">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [
            {
                "defaultValue": 10,
                "kind": "LocalArgument",
                "name": "count"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cursor"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "count",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": [
                        "reactions"
                    ]
                }
            ]
        },
        "name": "ReactionDetailsContainer_comment",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": "reactions",
                "args": null,
                "concreteType": "ReactionsConnection",
                "kind": "LinkedField",
                "name": "__ReactionDetails_reactions_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReactionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Reaction",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Reacter",
                                        "kind": "LinkedField",
                                        "name": "reacter",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "userID",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "username",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "CoralComment",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '7c486a215f3ba59c07982a2f9f73dc5a';
export default node;
