/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type QueueRoute_queue = {
    readonly count: number | null;
    readonly comments: {
        readonly viewNewEdges: ReadonlyArray<{
            readonly cursor: unknown;
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_comment">;
            };
        }> | null;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_comment">;
            };
        }>;
    };
    readonly " $refType": "QueueRoute_queue";
};
export type QueueRoute_queue$data = QueueRoute_queue;
export type QueueRoute_queue$key = {
    readonly " $data"?: QueueRoute_queue$data;
    readonly " $fragmentRefs": FragmentRefs<"QueueRoute_queue">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ModerateCardContainer_comment"
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [
            {
                "defaultValue": 5,
                "kind": "LocalArgument",
                "name": "count"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cursor"
            },
            {
                "defaultValue": "CREATED_AT_DESC",
                "kind": "LocalArgument",
                "name": "orderBy"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "count",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": [
                        "comments"
                    ]
                }
            ]
        },
        "name": "QueueRoute_queue",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
            },
            {
                "alias": "comments",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "orderBy",
                        "variableName": "orderBy"
                    }
                ],
                "concreteType": "CommentsConnection",
                "kind": "LinkedField",
                "name": "__Queue_comments_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "CoralComment",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    },
                                    (v1 /*: any*/)
                                ],
                                "storageKey": null
                            },
                            (v2 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "kind": "ClientExtension",
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "CommentEdge",
                                "kind": "LinkedField",
                                "name": "viewNewEdges",
                                "plural": true,
                                "selections": [
                                    (v2 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "CoralComment",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            (v1 /*: any*/)
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ]
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "ModerationQueue",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '1ab807733129154186680656c4a0c7c5';
export default node;
