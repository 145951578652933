/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type COMMENT_SORT = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "REACTION_DESC" | "REPLIES_DESC" | "%future added value";
export type QueueRouteLocal = {
    readonly moderationQueueSort: COMMENT_SORT | null;
    readonly " $refType": "QueueRouteLocal";
};
export type QueueRouteLocal$data = QueueRouteLocal;
export type QueueRouteLocal$key = {
    readonly " $data"?: QueueRouteLocal$data;
    readonly " $fragmentRefs": FragmentRefs<"QueueRouteLocal">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueueRouteLocal",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moderationQueueSort",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any;
(node as any).hash = '411170c70f81be9cbb09aaba63ee9c9c';
export default node;
