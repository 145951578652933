/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type QueueRoute_settings = {
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_settings">;
    readonly " $refType": "QueueRoute_settings";
};
export type QueueRoute_settings$data = QueueRoute_settings;
export type QueueRoute_settings$key = {
    readonly " $data"?: QueueRoute_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"QueueRoute_settings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueueRoute_settings",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModerateCardContainer_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = 'e9c28d2775d4c784153a74439f2c4780';
export default node;
