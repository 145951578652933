/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type AMPConfig_formValues = {
    readonly amp: boolean;
    readonly " $refType": "AMPConfig_formValues";
};
export type AMPConfig_formValues$data = AMPConfig_formValues;
export type AMPConfig_formValues$key = {
    readonly " $data"?: AMPConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"AMPConfig_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AMPConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amp",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '5f1742a123c1ca60ac337b5fdd7c915c';
export default node;
