/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type EditSiteForm_settings = {
    readonly staticURI: string | null;
    readonly " $refType": "EditSiteForm_settings";
};
export type EditSiteForm_settings$data = EditSiteForm_settings;
export type EditSiteForm_settings$key = {
    readonly " $data"?: EditSiteForm_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"EditSiteForm_settings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditSiteForm_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "staticURI",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '9dbb63812824e9f30e3ab894f5dd6bf0';
export default node;
