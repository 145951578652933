/* tslint:disable */
/* eslint-disable */
/* @relayHash 801399378b82ee4af8285b01ffe6d97c */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ExpertSelectionQueryVariables = {
    storyID?: string | null;
};
export type ExpertSelectionQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ExpertSelectionContainer_query">;
};
export type ExpertSelectionQuery = {
    readonly response: ExpertSelectionQueryResponse;
    readonly variables: ExpertSelectionQueryVariables;
};



/*
query ExpertSelectionQuery(
  $storyID: ID
) {
  ...ExpertSelectionContainer_query_2TMrIp
}

fragment ExpertSelectionContainer_query_2TMrIp on Query {
  viewer {
    id
    username
  }
  story(id: $storyID) {
    settings {
      experts {
        id
        email
        username
      }
    }
    id
  }
  coralusers(first: 10) {
    edges {
      node {
        id
        email
        username
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "storyID"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any, v4 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 10
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ExpertSelectionQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "storyID",
                            "variableName": "storyID"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "ExpertSelectionContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ExpertSelectionQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoralUser",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "id",
                            "variableName": "storyID"
                        }
                    ],
                    "concreteType": "Story",
                    "kind": "LinkedField",
                    "name": "story",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "StorySettings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CoralUser",
                                    "kind": "LinkedField",
                                    "name": "experts",
                                    "plural": true,
                                    "selections": [
                                        (v1 /*: any*/),
                                        (v3 /*: any*/),
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "UsersConnection",
                    "kind": "LinkedField",
                    "name": "coralusers",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CoralUser",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        (v3 /*: any*/),
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "coralusers(first:10)"
                },
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "filters": [
                        "role",
                        "status",
                        "query"
                    ],
                    "handle": "connection",
                    "key": "ExpertSelection_coralusers",
                    "kind": "LinkedHandle",
                    "name": "coralusers"
                }
            ]
        },
        "params": {
            "id": "801399378b82ee4af8285b01ffe6d97c",
            "metadata": {},
            "name": "ExpertSelectionQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '3014c798bc71d0a734461ebb07b3ac8e';
export default node;
