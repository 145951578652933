/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ConversationModalCommentContainer_comment = {
    readonly id: string;
    readonly body: string | null;
    readonly createdAt: string;
    readonly author: {
        readonly username: string | null;
        readonly id: string;
    } | null;
    readonly replyCount: number;
    readonly parent: {
        readonly author: {
            readonly username: string | null;
            readonly id: string;
        } | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"MediaContainer_comment">;
    readonly " $refType": "ConversationModalCommentContainer_comment";
};
export type ConversationModalCommentContainer_comment$data = ConversationModalCommentContainer_comment;
export type ConversationModalCommentContainer_comment$key = {
    readonly " $data"?: ConversationModalCommentContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"ConversationModalCommentContainer_comment">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "concreteType": "CoralUser",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
            },
            (v0 /*: any*/)
        ],
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ConversationModalCommentContainer_comment",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
            },
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "replyCount",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CoralComment",
                "kind": "LinkedField",
                "name": "parent",
                "plural": false,
                "selections": [
                    (v1 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MediaContainer_comment"
            }
        ],
        "type": "CoralComment",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '1091b053205517faf7f24bd92c9cc2a8';
export default node;
