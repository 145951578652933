/* tslint:disable */
/* eslint-disable */
/* @relayHash ef103817b948cd42cf38a525dab81c87 */

import { ConcreteRequest } from "relay-runtime";
export type DeleteSSOSigningSecretInput = {
    clientMutationId: string;
    kid: string;
};
export type DeleteSSOSigningSecretMutationVariables = {
    input: DeleteSSOSigningSecretInput;
};
export type DeleteSSOSigningSecretMutationResponse = {
    readonly deleteSSOSigningSecret: {
        readonly settings: {
            readonly auth: {
                readonly integrations: {
                    readonly sso: {
                        readonly enabled: boolean;
                        readonly signingSecrets: ReadonlyArray<{
                            readonly kid: string;
                            readonly secret: string;
                            readonly createdAt: string;
                            readonly lastUsedAt: string | null;
                            readonly rotatedAt: string | null;
                            readonly inactiveAt: string | null;
                        }>;
                    };
                };
            };
        } | null;
        readonly clientMutationId: string;
    };
};
export type DeleteSSOSigningSecretMutation = {
    readonly response: DeleteSSOSigningSecretMutationResponse;
    readonly variables: DeleteSSOSigningSecretMutationVariables;
};



/*
mutation DeleteSSOSigningSecretMutation(
  $input: DeleteSSOSigningSecretInput!
) {
  deleteSSOSigningSecret(input: $input) {
    settings {
      auth {
        integrations {
          sso {
            enabled
            signingSecrets {
              kid
              secret
              createdAt
              lastUsedAt
              rotatedAt
              inactiveAt
            }
          }
        }
      }
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "Auth",
        "kind": "LinkedField",
        "name": "auth",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "AuthIntegrations",
                "kind": "LinkedField",
                "name": "integrations",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SSOAuthIntegration",
                        "kind": "LinkedField",
                        "name": "sso",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "enabled",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "SigningSecret",
                                "kind": "LinkedField",
                                "name": "signingSecrets",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "kid",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "secret",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "createdAt",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "lastUsedAt",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "rotatedAt",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "inactiveAt",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteSSOSigningSecretMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteSSOSigningSecretPayload",
                    "kind": "LinkedField",
                    "name": "deleteSSOSigningSecret",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteSSOSigningSecretMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteSSOSigningSecretPayload",
                    "kind": "LinkedField",
                    "name": "deleteSSOSigningSecret",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "ef103817b948cd42cf38a525dab81c87",
            "metadata": {},
            "name": "DeleteSSOSigningSecretMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '7f6c76933ff55718014fdcd2920b9c31';
export default node;
