/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type StoryTableContainer_query = {
    readonly settings: {
        readonly multisite: boolean;
    };
    readonly viewer: {
        readonly moderationScopes: {
            readonly sites: ReadonlyArray<{
                readonly id: string;
            }> | null;
        } | null;
    } | null;
    readonly stories: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"StoryRowContainer_story">;
            };
        }>;
    };
    readonly " $refType": "StoryTableContainer_query";
};
export type StoryTableContainer_query$data = StoryTableContainer_query;
export type StoryTableContainer_query$key = {
    readonly " $data"?: StoryTableContainer_query$data;
    readonly " $fragmentRefs": FragmentRefs<"StoryTableContainer_query">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [
            {
                "defaultValue": 10,
                "kind": "LocalArgument",
                "name": "count"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cursor"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "searchFilter"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "siteIDs"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "statusFilter"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "count",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": [
                        "stories"
                    ]
                }
            ]
        },
        "name": "StoryTableContainer_query",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Settings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "multisite",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CoralUser",
                "kind": "LinkedField",
                "name": "viewer",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserModerationScopes",
                        "kind": "LinkedField",
                        "name": "moderationScopes",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Site",
                                "kind": "LinkedField",
                                "name": "sites",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": "stories",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "query",
                        "variableName": "searchFilter"
                    },
                    {
                        "kind": "Variable",
                        "name": "siteIDs",
                        "variableName": "siteIDs"
                    },
                    {
                        "kind": "Variable",
                        "name": "status",
                        "variableName": "statusFilter"
                    }
                ],
                "concreteType": "StoriesConnection",
                "kind": "LinkedField",
                "name": "__StoryTable_stories_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "StoryEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Story",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "StoryRowContainer_story"
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '27fcf1888b15720e746e87732b564b08';
export default node;
