/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type StoryInfoDrawerContainer_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"ArchiveStoryActionsContainer_viewer" | "ModerateStoryButton_viewer">;
    readonly " $refType": "StoryInfoDrawerContainer_viewer";
};
export type StoryInfoDrawerContainer_viewer$data = StoryInfoDrawerContainer_viewer;
export type StoryInfoDrawerContainer_viewer$key = {
    readonly " $data"?: StoryInfoDrawerContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"StoryInfoDrawerContainer_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StoryInfoDrawerContainer_viewer",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ArchiveStoryActionsContainer_viewer"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModerateStoryButton_viewer"
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = 'b460de9fd6edcbf978664bf681853344';
export default node;
