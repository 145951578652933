/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ModerateCardDetailsContainer_settings = {
    readonly " $fragmentRefs": FragmentRefs<"LinkDetailsContainer_settings" | "AutomatedActionsContainer_settings">;
    readonly " $refType": "ModerateCardDetailsContainer_settings";
};
export type ModerateCardDetailsContainer_settings$data = ModerateCardDetailsContainer_settings;
export type ModerateCardDetailsContainer_settings$key = {
    readonly " $data"?: ModerateCardDetailsContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardDetailsContainer_settings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModerateCardDetailsContainer_settings",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LinkDetailsContainer_settings"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AutomatedActionsContainer_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '29b3a5c722befb45f83608f56687618e';
export default node;
