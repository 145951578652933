/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type STORY_STATUS = "CLOSED" | "OPEN" | "%future added value";
export type StoryStatusContainer_story = {
    readonly id: string;
    readonly status: STORY_STATUS;
    readonly isArchiving: boolean;
    readonly isArchived: boolean;
    readonly " $refType": "StoryStatusContainer_story";
};
export type StoryStatusContainer_story$data = StoryStatusContainer_story;
export type StoryStatusContainer_story$key = {
    readonly " $data"?: StoryStatusContainer_story$data;
    readonly " $fragmentRefs": FragmentRefs<"StoryStatusContainer_story">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StoryStatusContainer_story",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchiving",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchived",
            "storageKey": null
        }
    ],
    "type": "Story",
    "abstractKey": null
} as any;
(node as any).hash = 'b15a8b71ea89390ac8dfb1e26d1a40a4';
export default node;
