/* tslint:disable */
/* eslint-disable */
/* @relayHash f7b57c6d7c162285b543f9a8272cf03c */

import { ConcreteRequest } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type PromoteModeratorInput = {
    userID: string;
    siteIDs: Array<string>;
    clientMutationId: string;
};
export type PromoteModeratorMutationVariables = {
    input: PromoteModeratorInput;
};
export type PromoteModeratorMutationResponse = {
    readonly promoteModerator: {
        readonly user: {
            readonly id: string;
            readonly role: USER_ROLE;
            readonly moderationScopes: {
                readonly scoped: boolean;
                readonly sites: ReadonlyArray<{
                    readonly id: string;
                    readonly name: string;
                }> | null;
            } | null;
        };
        readonly clientMutationId: string;
    };
};
export type PromoteModeratorMutation = {
    readonly response: PromoteModeratorMutationResponse;
    readonly variables: PromoteModeratorMutationVariables;
};



/*
mutation PromoteModeratorMutation(
  $input: PromoteModeratorInput!
) {
  promoteModerator(input: $input) {
    user {
      id
      role
      moderationScopes {
        scoped
        sites {
          id
          name
        }
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "PromoteModeratorPayload",
            "kind": "LinkedField",
            "name": "promoteModerator",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoralUser",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "scoped",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "sites",
                                    "plural": true,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "PromoteModeratorMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "PromoteModeratorMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "id": "f7b57c6d7c162285b543f9a8272cf03c",
            "metadata": {},
            "name": "PromoteModeratorMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'b532bc599ef2781e395f74b6f50f1001';
export default node;
