/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type RTEConfig_formValues = {
    readonly rte: {
        readonly enabled: boolean;
        readonly strikethrough: boolean;
        readonly spoiler: boolean;
    };
    readonly " $refType": "RTEConfig_formValues";
};
export type RTEConfig_formValues$data = RTEConfig_formValues;
export type RTEConfig_formValues$key = {
    readonly " $data"?: RTEConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"RTEConfig_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RTEConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "RTEConfiguration",
            "kind": "LinkedField",
            "name": "rte",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "strikethrough",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "spoiler",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '2acd731513a11db4a195715da71fbeec';
export default node;
