/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type UserBadgesContainer_user = {
    readonly badges: ReadonlyArray<string> | null;
    readonly " $refType": "UserBadgesContainer_user";
};
export type UserBadgesContainer_user$data = UserBadgesContainer_user;
export type UserBadgesContainer_user$key = {
    readonly " $data"?: UserBadgesContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"UserBadgesContainer_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserBadgesContainer_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "badges",
            "storageKey": null
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = '5d5ce145ebfcd9fbecf3847b04182520';
export default node;
