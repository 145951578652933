/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type FEATURE_FLAG = "ALTERNATE_OLDEST_FIRST_VIEW" | "AVATARS" | "COMMENT_SEEN" | "CONFIGURE_PUBLIC_PROFILE_URL" | "DEFAULT_QA_STORY_MODE" | "DISABLE_LIVE_UPDATES" | "DISABLE_WARN_USER_OF_TOXIC_COMMENT" | "DISCUSSIONS" | "EMAIL_META" | "ENABLE_QA" | "ENABLE_RATINGS_AND_REVIEWS" | "EXTERNAL_MEDIA" | "NEW_COMMENT_COUNT" | "READ_MORE_NEW_TAB" | "REDUCED_SECURITY_MODE" | "RTE_SARCASM" | "SECTIONS" | "VIEWER_COUNT" | "Z_KEY" | "%future added value";
export type UserHistoryDrawerContainer_settings = {
    readonly organization: {
        readonly name: string;
    };
    readonly externalProfileURL: string | null;
    readonly featureFlags: ReadonlyArray<FEATURE_FLAG>;
    readonly " $fragmentRefs": FragmentRefs<"RecentHistoryContainer_settings" | "UserStatusChangeContainer_settings">;
    readonly " $refType": "UserHistoryDrawerContainer_settings";
};
export type UserHistoryDrawerContainer_settings$data = UserHistoryDrawerContainer_settings;
export type UserHistoryDrawerContainer_settings$key = {
    readonly " $data"?: UserHistoryDrawerContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"UserHistoryDrawerContainer_settings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserHistoryDrawerContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalProfileURL",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "featureFlags",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RecentHistoryContainer_settings"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserStatusChangeContainer_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '754626072c33bb82c4e99401171a067d';
export default node;
