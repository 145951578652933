/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type AkismetConfig_formValues = {
    readonly integrations: {
        readonly akismet: {
            readonly enabled: boolean;
            readonly ipBased: boolean;
            readonly key: string | null;
            readonly site: string | null;
        };
    };
    readonly " $refType": "AkismetConfig_formValues";
};
export type AkismetConfig_formValues$data = AkismetConfig_formValues;
export type AkismetConfig_formValues$key = {
    readonly " $data"?: AkismetConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"AkismetConfig_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AkismetConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "ExternalIntegrations",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AkismetExternalIntegration",
                    "kind": "LinkedField",
                    "name": "akismet",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ipBased",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "key",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "site",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '0b763ee59abbecac947a737d04574599';
export default node;
