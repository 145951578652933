import { Localized } from "@fluent/react/compat";
import React, { FunctionComponent } from "react";

import { Button, Flex, Icon, TextField } from "coral-ui/components/v2";

import styles from "./SiteSearchTextField.css";

interface Props {
  onSearch: (event: React.SyntheticEvent) => void;
  value: string;
}

const SiteSearchTextField: FunctionComponent<Props> = ({onSearch, value
}) => {
  return (
    <Flex>
      <Localized
        id="site-search-textField"
        attrs={{ "aria-label": true, placeholder: true }}
      >
        <TextField
          color="regular"
          className={styles.textField}
          data-testid="site-search-textField"
          readOnly
          placeholder="List of sites"
          aria-label="List of sites"
          variant="seamlessAdornment"
          value={value}
          onFocus={onSearch}
          onClick={onSearch}
          style={{ cursor: "default" }}
          adornment={
            <Localized
              id="site-search-searchButton"
              attrs={{ "aria-label": true }}
            >
              <Button
                data-testid="site-search-button"
                type="submit"
                color="dark"
                adornmentRight
                aria-label="Search"
                onClick={onSearch}
              >
                <Icon size="md">search</Icon>
              </Button>
            </Localized>
          }
        />
      </Localized>
    </Flex>
  );
};

export default SiteSearchTextField;
