/* tslint:disable */
/* eslint-disable */
/* @relayHash 7ba5dd0577e69430ecbfb95a45bfb133 */

import { ConcreteRequest } from "relay-runtime";
export type SetPasswordInput = {
    password: string;
    clientMutationId: string;
};
export type SetPasswordMutationVariables = {
    input: SetPasswordInput;
};
export type SetPasswordMutationResponse = {
    readonly setPassword: {
        readonly user: {
            readonly profiles: ReadonlyArray<{
                readonly __typename: string;
            }>;
        };
        readonly clientMutationId: string;
    };
};
export type SetPasswordMutation = {
    readonly response: SetPasswordMutationResponse;
    readonly variables: SetPasswordMutationVariables;
};



/*
mutation SetPasswordMutation(
  $input: SetPasswordInput!
) {
  setPassword(input: $input) {
    user {
      profiles {
        __typename
      }
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "profiles",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SetPasswordMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SetPasswordPayload",
                    "kind": "LinkedField",
                    "name": "setPassword",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoralUser",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SetPasswordMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SetPasswordPayload",
                    "kind": "LinkedField",
                    "name": "setPassword",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoralUser",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "7ba5dd0577e69430ecbfb95a45bfb133",
            "metadata": {},
            "name": "SetPasswordMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '74064c760fc349312689494d7a46d37a';
export default node;
