/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type AccountFeaturesConfig_formValues = {
    readonly accountFeatures: {
        readonly changeUsername: boolean;
        readonly deleteAccount: boolean;
        readonly downloadComments: boolean;
    };
    readonly " $refType": "AccountFeaturesConfig_formValues";
};
export type AccountFeaturesConfig_formValues$data = AccountFeaturesConfig_formValues;
export type AccountFeaturesConfig_formValues$key = {
    readonly " $data"?: AccountFeaturesConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountFeaturesConfig_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountFeaturesConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "CommenterAccountFeatures",
            "kind": "LinkedField",
            "name": "accountFeatures",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "changeUsername",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deleteAccount",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "downloadComments",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = 'fec76a505a19da849531b34f0b278898';
export default node;
