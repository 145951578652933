/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type CommentLengthConfig_formValues = {
    readonly charCount: {
        readonly enabled: boolean;
        readonly min: number | null;
        readonly max: number | null;
    };
    readonly " $refType": "CommentLengthConfig_formValues";
};
export type CommentLengthConfig_formValues$data = CommentLengthConfig_formValues;
export type CommentLengthConfig_formValues$key = {
    readonly " $data"?: CommentLengthConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentLengthConfig_formValues">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentLengthConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "CharCount",
            "kind": "LinkedField",
            "name": "charCount",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "min",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "max",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = 'f5d630534e37b7cbd3e71753e76f0275';
export default node;
