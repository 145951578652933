/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ConversationModalHeaderContainer_comment = {
    readonly story: {
        readonly id: string;
        readonly metadata: {
            readonly title: string | null;
        } | null;
        readonly url: string;
    };
    readonly id: string;
    readonly " $refType": "ConversationModalHeaderContainer_comment";
};
export type ConversationModalHeaderContainer_comment$data = ConversationModalHeaderContainer_comment;
export type ConversationModalHeaderContainer_comment$key = {
    readonly " $data"?: ConversationModalHeaderContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"ConversationModalHeaderContainer_comment">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ConversationModalHeaderContainer_comment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Story",
                "kind": "LinkedField",
                "name": "story",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "StoryMetadata",
                        "kind": "LinkedField",
                        "name": "metadata",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            (v0 /*: any*/)
        ],
        "type": "CoralComment",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '4772f80bb47912a96ee80f25313e7683';
export default node;
