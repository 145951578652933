/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type ConfigureWebhookEndpointContainer_settings = {
    readonly " $fragmentRefs": FragmentRefs<"EndpointDetails_settings">;
    readonly " $refType": "ConfigureWebhookEndpointContainer_settings";
};
export type ConfigureWebhookEndpointContainer_settings$data = ConfigureWebhookEndpointContainer_settings;
export type ConfigureWebhookEndpointContainer_settings$key = {
    readonly " $data"?: ConfigureWebhookEndpointContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"ConfigureWebhookEndpointContainer_settings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigureWebhookEndpointContainer_settings",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EndpointDetails_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '9d14bc9563ef931e4fdafd88b1207298';
export default node;
