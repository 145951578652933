/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type UserHistoryDrawerAllComments_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_viewer">;
    readonly " $refType": "UserHistoryDrawerAllComments_viewer";
};
export type UserHistoryDrawerAllComments_viewer$data = UserHistoryDrawerAllComments_viewer;
export type UserHistoryDrawerAllComments_viewer$key = {
    readonly " $data"?: UserHistoryDrawerAllComments_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"UserHistoryDrawerAllComments_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserHistoryDrawerAllComments_viewer",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModerateCardContainer_viewer"
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = '1328a2d134123b63db8ad3619728a5ff';
export default node;
