/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type SiteSelectorContainer_viewer = {
    readonly moderationScopes: {
        readonly scoped: boolean;
        readonly sites: ReadonlyArray<{
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"SiteSelectorSite_site">;
        }> | null;
    } | null;
    readonly " $refType": "SiteSelectorContainer_viewer";
};
export type SiteSelectorContainer_viewer$data = SiteSelectorContainer_viewer;
export type SiteSelectorContainer_viewer$key = {
    readonly " $data"?: SiteSelectorContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"SiteSelectorContainer_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteSelectorContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "UserModerationScopes",
            "kind": "LinkedField",
            "name": "moderationScopes",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scoped",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "sites",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "SiteSelectorSite_site"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = '95299fc4a4b97eb6363170503550ba84';
export default node;
