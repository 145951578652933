/* tslint:disable */
/* eslint-disable */
/* @relayHash d3a51e1ac92eadca99a9582a3a515d48 */

import { ConcreteRequest } from "relay-runtime";
export type MODERATION_MODE = "POST" | "PRE" | "SPECIFIC_SITES_PRE" | "%future added value";
export type STORY_MODE = "COMMENTS" | "QA" | "RATINGS_AND_REVIEWS" | "%future added value";
export type UpdateStorySettingsInput = {
    id: string;
    settings: UpdateStorySettings;
    clientMutationId: string;
};
export type UpdateStorySettings = {
    live?: LiveConfigurationInput | null;
    moderation?: MODERATION_MODE | null;
    premodLinksEnable?: boolean | null;
    messageBox?: StoryMessageBoxInput | null;
};
export type LiveConfigurationInput = {
    enabled?: boolean | null;
};
export type StoryMessageBoxInput = {
    enabled?: boolean | null;
    icon?: string | null;
    content?: string | null;
};
export type UpdateStorySettingsMutationVariables = {
    input: UpdateStorySettingsInput;
};
export type UpdateStorySettingsMutationResponse = {
    readonly updateStorySettings: {
        readonly story: {
            readonly settings: {
                readonly mode: STORY_MODE;
                readonly moderation: MODERATION_MODE;
                readonly live: {
                    readonly configurable: boolean;
                    readonly enabled: boolean;
                };
                readonly premodLinksEnable: boolean;
            };
        } | null;
    };
};
export type UpdateStorySettingsMutation = {
    readonly response: UpdateStorySettingsMutationResponse;
    readonly variables: UpdateStorySettingsMutationVariables;
};



/*
mutation UpdateStorySettingsMutation(
  $input: UpdateStorySettingsInput!
) {
  updateStorySettings(input: $input) {
    story {
      settings {
        mode
        moderation
        live {
          configurable
          enabled
        }
        premodLinksEnable
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "StorySettings",
        "kind": "LinkedField",
        "name": "settings",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mode",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "moderation",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "LiveConfiguration",
                "kind": "LinkedField",
                "name": "live",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "configurable",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enabled",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "premodLinksEnable",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateStorySettingsMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateStorySettingsPayload",
                    "kind": "LinkedField",
                    "name": "updateStorySettings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Story",
                            "kind": "LinkedField",
                            "name": "story",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateStorySettingsMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateStorySettingsPayload",
                    "kind": "LinkedField",
                    "name": "updateStorySettings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Story",
                            "kind": "LinkedField",
                            "name": "story",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "d3a51e1ac92eadca99a9582a3a515d48",
            "metadata": {},
            "name": "UpdateStorySettingsMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'e04c60557c895555b1486eb9714b7b65';
export default node;
