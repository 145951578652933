/* tslint:disable */
/* eslint-disable */
/* @relayHash 6bcce14d9092ec2c52c9318792370819 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type DisableWebhookEndpointInput = {
    clientMutationId: string;
    id: string;
};
export type DisableWebhookEndpointMutationVariables = {
    input: DisableWebhookEndpointInput;
};
export type DisableWebhookEndpointMutationResponse = {
    readonly disableWebhookEndpoint: {
        readonly endpoint: {
            readonly " $fragmentRefs": FragmentRefs<"ConfigureWebhookEndpointContainer_webhookEndpoint">;
        };
    };
};
export type DisableWebhookEndpointMutation = {
    readonly response: DisableWebhookEndpointMutationResponse;
    readonly variables: DisableWebhookEndpointMutationVariables;
};



/*
mutation DisableWebhookEndpointMutation(
  $input: DisableWebhookEndpointInput!
) {
  disableWebhookEndpoint(input: $input) {
    endpoint {
      ...ConfigureWebhookEndpointContainer_webhookEndpoint
      id
    }
  }
}

fragment ConfigureWebhookEndpointContainer_webhookEndpoint on WebhookEndpoint {
  ...EndpointDangerZone_webhookEndpoint
  ...EndpointDetails_webhookEndpoint
  ...EndpointStatus_webhookEndpoint
}

fragment ConfigureWebhookEndpointForm_webhookEndpoint on WebhookEndpoint {
  id
  url
  events
  all
}

fragment EndpointDangerZone_webhookEndpoint on WebhookEndpoint {
  id
  enabled
}

fragment EndpointDetails_webhookEndpoint on WebhookEndpoint {
  ...ConfigureWebhookEndpointForm_webhookEndpoint
}

fragment EndpointStatus_webhookEndpoint on WebhookEndpoint {
  id
  enabled
  signingSecret {
    secret
    createdAt
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DisableWebhookEndpointMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DisableWebhookEndpointPayload",
                    "kind": "LinkedField",
                    "name": "disableWebhookEndpoint",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "WebhookEndpoint",
                            "kind": "LinkedField",
                            "name": "endpoint",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ConfigureWebhookEndpointContainer_webhookEndpoint"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DisableWebhookEndpointMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DisableWebhookEndpointPayload",
                    "kind": "LinkedField",
                    "name": "disableWebhookEndpoint",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "WebhookEndpoint",
                            "kind": "LinkedField",
                            "name": "endpoint",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "enabled",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "events",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "all",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SigningSecret",
                                    "kind": "LinkedField",
                                    "name": "signingSecret",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "secret",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "6bcce14d9092ec2c52c9318792370819",
            "metadata": {},
            "name": "DisableWebhookEndpointMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '1421baa087a3faffb8e16621668afaa8';
export default node;
