/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type AnnouncementConfigContainer_settings = {
    readonly announcement: {
        readonly content: string;
        readonly duration: number;
        readonly createdAt: string;
    } | null;
    readonly " $refType": "AnnouncementConfigContainer_settings";
};
export type AnnouncementConfigContainer_settings$data = AnnouncementConfigContainer_settings;
export type AnnouncementConfigContainer_settings$key = {
    readonly " $data"?: AnnouncementConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"AnnouncementConfigContainer_settings">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AnnouncementConfigContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Announcement",
            "kind": "LinkedField",
            "name": "announcement",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "content",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duration",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any;
(node as any).hash = '3a1539284cfe9976f8d6bc202162e586';
export default node;
