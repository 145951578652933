/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type RestrictedContainer_viewer = {
    readonly username: string | null;
    readonly " $refType": "RestrictedContainer_viewer";
};
export type RestrictedContainer_viewer$data = RestrictedContainer_viewer;
export type RestrictedContainer_viewer$key = {
    readonly " $data"?: RestrictedContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"RestrictedContainer_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RestrictedContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
        }
    ],
    "type": "CoralUser",
    "abstractKey": null
} as any;
(node as any).hash = '2264d13e7385f141f1349bf71479bf17';
export default node;
