/* tslint:disable */
/* eslint-disable */
/* @relayHash 4e7d55439086f3b2393d86f324e149cd */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type COMMENT_BODY_FORMAT = "HTML" | "PLAIN_TEXT" | "%future added value";
export type CreateExternalModerationPhaseInput = {
    clientMutationId: string;
    name: string;
    url: string;
    format: COMMENT_BODY_FORMAT;
    timeout: number;
};
export type CreateExternalModerationPhaseMutationVariables = {
    input: CreateExternalModerationPhaseInput;
};
export type CreateExternalModerationPhaseMutationResponse = {
    readonly createExternalModerationPhase: {
        readonly phase: {
            readonly id: string;
        };
        readonly settings: {
            readonly " $fragmentRefs": FragmentRefs<"ModerationPhasesConfigContainer_settings">;
        };
    };
};
export type CreateExternalModerationPhaseMutation = {
    readonly response: CreateExternalModerationPhaseMutationResponse;
    readonly variables: CreateExternalModerationPhaseMutationVariables;
};



/*
mutation CreateExternalModerationPhaseMutation(
  $input: CreateExternalModerationPhaseInput!
) {
  createExternalModerationPhase(input: $input) {
    phase {
      id
    }
    settings {
      ...ModerationPhasesConfigContainer_settings
      id
    }
  }
}

fragment ExternalModerationPhaseRow_phase on ExternalModerationPhase {
  id
  name
  enabled
}

fragment ModerationPhasesConfigContainer_settings on Settings {
  integrations {
    external {
      phases {
        ...ExternalModerationPhaseRow_phase
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "ExternalModerationPhase",
        "kind": "LinkedField",
        "name": "phase",
        "plural": false,
        "selections": [
            (v2 /*: any*/)
        ],
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateExternalModerationPhaseMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateExternalModerationPhasePayload",
                    "kind": "LinkedField",
                    "name": "createExternalModerationPhase",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ModerationPhasesConfigContainer_settings"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateExternalModerationPhaseMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateExternalModerationPhasePayload",
                    "kind": "LinkedField",
                    "name": "createExternalModerationPhase",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ExternalIntegrations",
                                    "kind": "LinkedField",
                                    "name": "integrations",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CustomExternalIntegration",
                                            "kind": "LinkedField",
                                            "name": "external",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ExternalModerationPhase",
                                                    "kind": "LinkedField",
                                                    "name": "phases",
                                                    "plural": true,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "name",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "enabled",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "4e7d55439086f3b2393d86f324e149cd",
            "metadata": {},
            "name": "CreateExternalModerationPhaseMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '04cf91fd8406e0ceed25cdeee9ef5684';
export default node;
